<template>
    <div class="input-group">
        <input type="text" @keydown.enter="search" v-model="q" class="form-control" placeholder="Search for...">
        <span class="input-group-btn">
        <button @click="search" class="btn btn-primary border-radius-0" type="button">
            <i class="fa fa-search"></i>
        </button>
        </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            q: '',
        };
    },

    methods: {
        search() {
            window.location.href = '/search?q=' + encodeURIComponent(this.q);
        },
    },
};
</script>
