import moment from 'moment'

// Dates
export function fromNow(value) {
    return moment.utc(value).local().fromNow()
}

export function fromUTC(value, format = 'MMMM D, YYYY') {
    return moment.utc(value).local().format(format)
}

export function dateFormat(value, format = 'MMMM D, YYYY') {
    return moment(value).format(format)
}

// Strings
export function replaceFirst(string, replace) {
    return string.replace(replace, ' ')
}

export function truncate(string, length, append) {
    return string && string.length > length ? string.substr(0, length) + append : string
}

export function genreString(arr, glue = ', ') {
    return arr.reduce((acc, g) => {
        return acc === '' ? g.genre : acc + glue + g.genre
    }, '')
}

export function categoryString(arr, glue = ', ') {
    return arr.reduce((acc, category) => {
        return acc === '' ? category.name : acc + glue + category.name
    }, '')
}

export function titleCase(str) {
    return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

// present a readable location if it exists
// city, state_code
// city
// state
// message for no location /default none
export function cityState(obj, noLocation = '') {
    if (obj.city && obj.state_code) {
        return obj.city + ', ' + obj.state_code
    } else if (obj.city) {
        return obj.city
    } else if (obj.state) {
        return obj.state
    }
    return noLocation
}

export function interval(str) {
    if (str == 'annual') {
        return 'year'
    } else {
        return str
    }
}
