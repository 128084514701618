<template>
  <div>
    <div class="d-none d-lg-flex card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="mb-0">IOTM News</h4>
        <a href="/newsletter/subscribe" class="btn btn-sm btn-primary">
          Join Our Newsletter
        </a>
      </div>

      <div class="card-body">
        <div class="mb-3 twitter-scroll-container">
          <a
            class="twitter-timeline"
            href="https://x.com/Indieonthemove?ref_src=twsrc%5Etfw"
            >Tweets by Indieonthemove</a
          >
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="!hideAds">
      <div id="ads-medium-rectangle" class="mt-2 d-none d-lg-flex">
        <ins class="adsbygoogle" style="height: 250px; width: 300px; display: block"
          data-ad-client="ca-pub-7294664740924242" data-ad-slot="6104773750" data-ad-format="medium-rectangle"></ins>
      </div>
    </div>

    <div class="mt-2 d-none d-lg-flex card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="mb-0">Venue Reviews</h4>
        <a href="/venue-commentsreviews" class="btn btn-sm btn-primary">
          More Reviews
        </a>
      </div>
      <div class="card-body">
        <reviews limit="5"></reviews>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="!hideAds">
      <div id="ads-skyscraper" class="mt-2 d-none d-lg-flex">
        <ins class="adsbygoogle" style="height: 600px; width: 300px; display: block"
          data-ad-client="ca-pub-7294664740924242" data-ad-slot="6104773750" data-ad-format="skyscraper"></ins>
      </div>
    </div>
  </div>
</template>

<script>
import reviews from "../components/home/reviews.vue";

export default {
  components: { reviews },

  props: {
    statusCode: {
      default: 200,
    },
  },

  mounted() {
    console.log('Component mounted');
    console.log('hideAds:', this.hideAds);
    if (!this.hideAds) {
      console.log('Loading AdSense script');
      this.loadAdSenseScript();
    } else {
      console.log('Ads are hidden, not loading script');
    }
  },

  methods: {
    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        console.log('Creating AdSense script');
        const script = document.createElement('script');
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        script.onload = () => {
          console.log('AdSense script loaded');
          this.$nextTick(() => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          });
          resolve();
        };
        script.onerror = (error) => {
          console.error('Error loading AdSense script:', error);
          reject(error);
        };
        document.head.appendChild(script);
        console.log('AdSense script appended to head');
      });
    },
  },

  computed: {
    hideAds() {
      return (
        (window.User && window.User["old_browser"]) ||
        (window.User && window.User.is_premium) ||
        (window.User && window.User.is_deluxe) ||
        this.statusCode != 200
      );
    },
  },
};
</script>

<style>
.twitter-scroll-container {
  max-height: 40vh;
  overflow: scroll;
}
</style>
