<template>
    <div class="scroll-container">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>Venue</th>
                    <th>Location</th>
                    <th v-if="deleteable" class="text-right">Remove</th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="venue in venues">
                    <td>
                        <a :href="'/venues/' + venue.slug">{{ venue.title }}</a>
                    </td>
                    <td>{{ cityState(venue) }}</td>
                    <td v-if="deleteable" class="text-right">
                        <a href="#" @click="$emit('removeVenue', venue)" class="p-1 btn text-danger btn-link">
                            <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
/* global $ */
import {
    cityState,
} from "../../filters/index.js";

export default {
    props: {
        venues: {
            type: Array,
            required: true,
        },
        deleteable: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        cityState
    },

    name: 'quickpitch-venues',
};
</script>

<style scoped>
.scroll-container {
    max-height: 35vh;
    overflow: scroll;
    border: 1px solid #eceeef;
}
</style>
