<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h4 class="m-0">
                My Photos <span v-show="photos.length">({{ photos.length }})</span>
            </h4>

            <div>
                <button @click="showModal = true" class="btn btn-outline-primary btn-sm">
                    <i class="fa fa-plus pr-2"></i>
                    Add
                </button>
            </div>
            <modal v-if="showModal">
                <template v-slot:body>
                    <div class="text-center">
                        <photo-upload v-if="userUuid" :url="`/api/users/${userUuid}/photos`"></photo-upload>
                        <button class="btn btn-link" @click="showModal = false">Back</button>
                    </div>
                </template>
            </modal>
        </div>

        <div class="card-body">
            <div v-if="!photos.length && !initialLoad" class="container text-center my-5 py-3 alert alert-info">
                No Photos Found
            </div>
            <div v-for="v in photos" key="photo.uuid" class="row pb-3">
                <a @click="activePhoto = v" class="cursor-pointer col-md-3 pb-2 text-center">
                    <img class="img-fluid" :src="v.url + '?w=300&h=300&fit=crop'" />
                </a>

                <div class="col-md-9 pb-2">
                    <div class="row">
                        <div class="col-7">
                            <p><b class="pr-2">Added:</b>{{ fromUTC(v.created_at) }}</p>
                            <p v-if="v.title"><b class="pr-2">Title:</b>{{ v.title }}</p>
                            <p v-if="v.caption"><b class="pr-2">Caption:</b>{{ v.caption }}</p>
                            <p v-if="v.venue"><b class="pr-2">Venue:</b>{{ v.venue }}</p>
                        </div>

                        <div class="col-5 text-right">
                            <button
                                class="d-none d-sm-block btn-sm btn btn-secondary ml-auto mb-1"
                                :disabled="isProfile(v)"
                                @click="setProfile(v)"
                            >
                                <i class="fa fa-user"></i>
                                <span> Set Profile Picture </span>
                            </button>
                            <a class="btn-sm btn btn-secondary" :href="'/photos/' + v.uuid + '/edit'">
                                <i class="fa fa-pencil"></i>
                            </a>
                            <a class="btn-sm btn btn-danger" @click="deletePhoto(v.uuid)">
                                <i class="text-white fa fa-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <preview @close="activePhoto = false" :hidecontrols="true" :photo="activePhoto"></preview>
    </div>
</template>

<script>
import preview from '../photoPreview.vue';
import photoUpload from '../photoUpload.vue';
import modal from '../modal.vue';
import btnState from '../btnState.vue';
import {
    fromUTC,
} from "../../filters/index.js";

export default {
    components: { preview, modal, photoUpload, btnState },

    data() {
        return {
            activePhoto: false,
            photos: [],
            initialLoad: true,
            showModal: false,
        };
    },

    created() {
        this.fetchPhotos();
    },

    computed: {
        userUuid() {
            return window.User.uuid;
        },
    },

    methods: {
        fromUTC,
        setProfile(photo) {
            axios.post('/api/users/' + window.User.uuid + '/thumbnail/' + photo.uuid).then(() => {
                window.location.reload();
            });
        },

        fetchPhotos() {
            axios.get('/api/users/' + window.User.uuid + '/photos').then(res => {
                this.photos = res.data.data;
                this.initialLoad = false;
            });
        },

        isProfile(photo) {
            if (photo && photo.url === window.User.img_url) {
                return true;
            }
            return false;
        },

        deletePhoto(id) {
            axios.delete('/api/photos/' + id).then(() => {
                this.fetchPhotos();
            });
        },
    },
};
</script>
