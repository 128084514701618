<template>
    <div>
        <nav class="navbar bg-white sub-nav navbar-bordered">
            <ul class="container nav nav-pills nav-centered">
                <li class="nav-item m-1" v-for="option in options">
                    <a
                        class="btn"
                        @click="
                            selectOption(option);
                            clearQuery();
                            fetchData();
                        "
                        :class="[
                            option.name === selectedOption.name
                                ? 'btn-primary text-white'
                                : 'btn-secondary',
                        ]"
                    >
                        {{ option.name }}
                    </a>
                </li>
            </ul>
        </nav>
        <deluxe-modal
            v-model="showDeluxeModal"
            redirect="/booking-inquiries"
        ></deluxe-modal>
        <div class="container">
            <div class="row mt-3">
                <div class="col-lg-8 col-xl-9">
                    <div class="card">
                        <transition-group name="fade" mode="in" v-if="!loading">
                            <div
                                class="px-4 card-header d-flex flex-row justify-content-between"
                                key="optionName"
                            >
                                <div class="font-weight-bold">
                                    {{ selectedOption.name }}
                                </div>
                                <input
                                    type="text"
                                    @keydown.enter.prevent="fetchData()"
                                    @keyup.delete="clearQuery()"
                                    @keydown.delete="clearQuery()"
                                    v-model="query.q"
                                    placeholder="Search for inquiries..."
                                    class="form-control col-xl-4 col-lg-2 ml-lg-auto"
                                />
                                <button
                                    class="btn btn-primary ml-2"
                                    @click.prevent="fetchData()"
                                >
                                    Search
                                </button>
                            </div>
                            <div
                                v-if="inquiries.length === 0 || showDeluxeModal"
                                class="text-center alert alert-info m-4"
                                key="noInquiries"
                                v-text="noResultsText"
                            ></div>
                            <div
                                v-else-if="
                                    selectedOption.name === 'QuickPitches'
                                "
                                class="table-responsive card-body"
                                key="quickpitches"
                            >
                                <table class="table table-nolines table-hover">
                                    <thead>
                                        <tr>
                                            <th>Venues</th>
                                            <th class="d-none d-sm-table-cell">
                                                Name/Subject
                                            </th>
                                            <th>Sent/Denied</th>
                                            <th>Analytics</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="n in inquiries">
                                            <td>
                                                {{ n.good_venues_count }}
                                            </td>
                                            <td
                                                class="d-none d-sm-table-cell trix-content"
                                                style="
                                                    max-width: 140px;
                                                    overflow-y: hidden;
                                                "
                                                v-html="n.name"
                                            ></td>
                                            <td
                                                v-if="n.date_sent"
                                                class="text-primary"
                                            >
                                                {{ fromUTC(n.date_sent) }}
                                            </td>
                                            <td
                                                v-else-if="n.date_denied"
                                                class="text-danger"
                                            >
                                                {{ fromUTC(n.date_denied) }}
                                            </td>
                                            <td v-else>Pending</td>
                                            <td>
                                                <a
                                                    :href="
                                                        '/quickpitch/' +
                                                        n.uuid +
                                                        '/analytics'
                                                    "
                                                >
                                                    View
                                                </a>
                                            </td>
                                            <td>
                                                <a
                                                    :href="
                                                        '/quickpitch/' + n.uuid
                                                    "
                                                    class="btn btn-sm btn-secondary mr-2"
                                                >
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                                <a
                                                    @click="deleteQp(n)"
                                                    class="btn btn-sm btn-danger text-white"
                                                >
                                                    <i class="fa fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div
                                v-else-if="
                                    selectedOption.name === 'Single Emails'
                                "
                                class="table-responsive card-body"
                                key="inquiries"
                            >
                                <table class="table table-nolines table-hover">
                                    <thead>
                                        <tr>
                                            <th>Venue</th>
                                            <th class="d-none d-sm-table-cell">
                                                Subject
                                            </th>
                                            <th>Date Sent</th>
                                            <th v-if="isDeluxe">Analytics</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="n in inquiries">
                                            <td
                                                class="text-wrap"
                                                style="max-width: 120px"
                                            >
                                                <a
                                                    v-if="n.venue"
                                                    :class="{
                                                        'text-muted':
                                                            n.read_at ||
                                                            n.venue.data
                                                                .status ===
                                                                'offline',
                                                    }"
                                                    :href="
                                                        venueUrl(n.venue.data)
                                                    "
                                                >
                                                    {{ n.venue.data.title }}
                                                </a>
                                            </td>
                                            <td
                                                class="d-none d-sm-table-cell text-wrap trix-content"
                                                style="
                                                    max-width: 120px;
                                                    overflow-y: hidden;
                                                "
                                                v-html="n.subject"
                                            ></td>
                                            <td>
                                                {{ fromUTC(n.created_at) }}
                                            </td>
                                            <td v-if="isDeluxe">
                                                <a
                                                    :href="
                                                        '/booking-inquiries/' +
                                                        n.uuid +
                                                        '/analytics'
                                                    "
                                                >
                                                    View
                                                </a>
                                            </td>
                                            <td>
                                                <a
                                                    @click="active = n"
                                                    class="btn btn-sm btn-secondary mr-2"
                                                >
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                                <a
                                                    @click="deleteInquiry(n)"
                                                    class="btn btn-sm btn-danger text-white"
                                                >
                                                    <i class="fa fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </transition-group>
                        <loading v-else></loading>

                        <modal
                            width="600"
                            @close="active = false"
                            v-if="active"
                        >
                            <template v-slot:body>
                                <p
                                    style="white-space: pre-wrap"
                                    v-html="active.message"
                                ></p>
                            </template>
                        </modal>
                        <pagination
                            :pagination="pagination"
                            :loading="loading"
                            @pageChange="updatePage"
                        ></pagination>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-3">
                    <news></news>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global swal */
import loading from "../../components/loading.vue";
import pagination from "../../components/pagination.vue";
import modal from "../../components/modal.vue";
import deluxeModal from "../../components/deluxeModal.vue";
import Query from "../../query/query.js";
import {
    fromUTC,
} from "../../filters/index.js";

export default {
    components: { pagination, modal, loading, deluxeModal },

    name: "booking-inquiries",

    data() {
        return {
            selectedOption: "Single Emails",
            options: [
                {
                    name: "Single Emails",
                },
                {
                    name: "QuickPitches",
                },
            ],
            query: new Query({
                include: "",
                page: "",
                type: "inquiries",
                q: "",
            }),
            pagination: {
                current_page: 1,
            },
            showDeluxeModal: false,
            inquiries: [],
            fetchError: false,
            loading: true,
            active: false,
        };
    },

    created() {
        this.query.parse(location.search);
        if (this.query.type == "quickpitches") {
            if (this.isDeluxe) {
                this.selectedOption = this.options[1];
            } else {
                this.selectedOption = this.options[1];
                this.showDeluxeModal = true;
            }
        } else {
            this.selectedOption = this.options[0];
        }
        this.selectOption(this.selectedOption);
    },

    methods: {
        fromUTC,
        selectOption(option) {
            this.loading = true;
            this.selectedOption = option;
            if (option.name === "Single Emails") {
                this.query.page = 1;
                this.query.type = "inquiries";
                this.handleQueryUpdate();
                this.fetchInquiries();
            } else if (!this.isDeluxe) {
                this.query.page = 1;
                this.query.type = "quickpitches";
                this.handleQueryUpdate();
                this.showDeluxeModal = true;
                return;
            } else {
                this.query.page = 1;
                this.query.type = "quickpitches";
                this.handleQueryUpdate();
                this.fetchQuickpitches();
            }
        },
        fetchData() {
            if (this.selectedOption === this.options[0]) {
                this.fetchInquiries();
            } else {
                this.fetchQuickpitches();
            }
        },
        fetchQuickpitches() {
            axios
                .get("/api/quickpitch?" + this.query.toString())
                .then((response) => {
                    this.pagination = response.data.meta.pagination;
                    this.inquiries = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.fetchError = error.data;
                });
        },
        venueUrl(venue) {
            if (venue.status === "offline") {
                return null;
            } else {
                return "/venues/" + venue.slug;
            }
        },
        fetchInquiries() {
            axios
                .get("/api/inquiry-venues?" + this.query.toString())
                .then((response) => {
                    this.pagination = response.data.meta.pagination;
                    this.inquiries = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.fetchError = error.data;
                });
        },

        deleteQp(n) {
            axios.delete("/api/quickpitch/" + n.uuid).then(() => {
                swal("Deleted!", "Quickpitch Deleted", "success");
                this.fetchQuickpitches();
            });
        },
        deleteInquiry(n) {
            axios.delete("/api/inquiry-venues/" + n.uuid).then(() => {
                swal("Deleted!", "Inquiry Deleted", "success");
                this.fetchInquiries();
            });
        },
        handleQueryUpdate() {
            this.query.push();
            if (this.query.type === "quickpitches") {
                this.fetchQuickpitches();
            } else {
                this.fetchInquiries();
            }
        },
        updatePage(number) {
            this.loading = true;
            this.query.page = number;
            this.pagination.current_page = number;
            this.handleQueryUpdate();
        },
        clearQuery() {
            this.query.q = "";
        },
    },
    computed: {
        isDeluxe() {
            return window.User.is_deluxe;
        },

        noResultsText() {
            var type = this.selectedOption.name;
            if (this.showDeluxeModal) {
                return "Upgrade to deluxe to create QuickPitches.";
            }
            if (this.selectedOption.name === "Single Emails") {
                var type = this.selectedOption.name.toLowerCase();
            }
            return `No ${type} found.`;
        },
    },
};
</script>

<style scoped>
.table-nolines th {
    border-top: none;
}
.table-nolines td {
    border-top: none;
}
</style>
