<template>
    <div>
        <table v-if="!loading" class="table">
            <tbody class="bg-white">
                <tr v-for="(availability, index) in items">
                    <td :class="index === 0 ? 'pt-0 border-0 row' : 'row'">
                        <div class="col-auto">
                            <img v-if="availability.img_url" class="img-fluid"
                                :src="availability.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word"
                                    :href="'/show-availabilities/' + availability.slug + '/' + availability.uuid">
                                    {{ truncate(availability.title, 100, '...') }}
                                </a>
                            </h6>
                            <p class="mb-0 break-word">
                                {{ availability.venue ? availability.venue.data.title : availability.venue_name }}
                            </p>
                            <p class="mb-0">
                                {{ cityState(availability) }}
                            </p>
                            <p class="mb-0 d-none d-sm-inline" v-if="availability.genres">
                                {{ genreString(availability.genres.data) }}
                            </p>
                        </div>
                    </td>
                    <td class="date-cell">{{ dateFormat(availability.date, 'MM-DD-YY h:mm a') }}</td>
                </tr>
            </tbody>
        </table>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';
import {
    genreString,
    dateFormat,
    truncate,
    cityState,
} from "../../filters/index.js";

export default {
    props: ['loading', 'items', 'query', 'showDistance'],

    name: 'availability-results',

    components: { loading },

    methods: {
        genreString,
        dateFormat,
        truncate,
        cityState,
    }
};
</script>
