<template>
    <div>
        <nav class="navbar bg-white sub-nav navbar-bordered">
            <ul class="container nav nav-pills nav-centered">
                <li class="nav-item m-1" @click="setView('venues')">
                    <a class="btn" :class="[this.view === 'venues' ? 'btn-primary text-white' : 'btn-secondary']">
                        Venues
                    </a>
                </li>
                <li class="nav-item m-1" @click="setView('users')">
                    <a class="btn" :class="[this.view === 'users' ? 'btn-primary text-white' : 'btn-secondary']">
                        Users
                    </a>
                </li>
                <li class="nav-item m-1" @click="setView('genres')">
                    <a class="btn" :class="[this.view === 'genres' ? 'btn-primary text-white' : 'btn-secondary']">
                        Genres
                    </a>
                </li>
                <li class="nav-item m-1" @click="setView('locations')">
                    <a class="btn" :class="[this.view === 'locations' ? 'btn-primary text-white' : 'btn-secondary']">
                        Locations
                    </a>
                </li>
            </ul>
        </nav>

        <div class="container">
            <div class="row mt-3">
                <div class="col-lg-8 col-xl-9">
                    <transition name="component-fade" mode="out-in">
                        <component :is="view"></component>
                    </transition>
                </div>

                <div class="col-lg-4 col-xl-3">
                    <news></news>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import users from './users.vue';
import venues from './venues.vue';
import locations from './locations.vue';
import genres from './genres.vue';
import Query from '../../../query/query.js';

export default {
    components: { users, venues, locations, genres },
    props: {
        uuid: {
            required: true,
            type: String,
        },
    },

    created() {
        this.query.parse(location.search);

        if (this.query.follow_type) this.view = this.query.follow_type;
    },

    methods: {
        setView(view) {
            this.view = view;
            this.query.follow_type = this.view;
            this.query.push();
        },
    },

    data() {
        return {
            view: 'users',
            query: new Query({
                follow_type: '',
            }),
        };
    },
};
</script>
