<template>
    <div>
        <table v-if="!loading" class="table table-hover bordered">
            <thead class="bg-primary">
                <tr class="small text-white">
                    <th class="cursor-pointer" @click="$emit('sortBy', 'display_name')">
                        <i :class="sortClass('display_name')"></i>
                        User
                    </th>
                    <th class="d-none d-sm-table-cell">Type</th>
                    <th v-show="isSortedBy('created_at')" class="cursor-pointer" @click="$emit('sortBy', 'created_at')">
                        <i :class="sortClass('created_at')"></i>
                        Created
                    </th>
                    <th v-show="isSortedBy('distance')" class="cursor-pointer" @click="$emit('sortBy', 'distance')">
                        <i :class="sortClass('distance')"></i>
                        Distance
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="user in users">
                    <td class="row">
                        <div class="col-auto">
                            <img v-if="user.img_url" class="img-fluid" :src="user.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word" :href="'/users/' + user.username">{{ user.display_name }}</a>
                            </h6>
                            <p class="mb-0">{{ user.follower_count }} followers</p>
                            <p class="mb-0">
                                {{ cityState(user) }}
                            </p>
                            <p v-if="user.genres">
                                {{ genreString(user.genres.data) }}
                            </p>
                        </div>
                    </td>
                    <td class="d-none d-sm-table-cell">{{ titleCase(userType(user.type)) }}</td>
                    <td v-show="isSortedBy('created_at')">
                        {{ fromUTC(user.created_at, 'MM-DD-YY') }}
                    </td>
                    <td v-show="isSortedBy('distance')">{{ user.distance.toFixed(1) }} miles</td>
                </tr>
            </tbody>
        </table>
        <div v-if="usersEmpty" class="card card-body text-center">
            <p class="m-3">
                No users found. <span v-if="query.q.length < 2">Use at least 2 characters to search.</span>
            </p>
        </div>

        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';
import {
    genreString,
    fromUTC,
    titleCase,
    cityState,
} from "../../filters/index.js";

export default {
    props: ['loading', 'users', 'query', 'showDistance'],

    name: 'users-results',

    components: { loading },

    computed: {
        usersEmpty() {
            return !this.loading && !this.users[0];
        },
    },

    methods: {
        genreString,
        fromUTC,
        titleCase,
        cityState,
        quickpitchAdd(user) {
            this.$emit('quickpitchAdd', user);
        },

        userType(type) {
            return type == 'Band' ? 'Band/Artist' : type;
        },

        sortClass(field) {
            return {
                'd-none': !(this.query.sort_by === field),
                fa: true,
                'fa-sort-amount-asc': this.query.sort_order === 'ascending',
                'fa-sort-amount-desc': this.query.sort_order === 'descending',
            };
        },

        isSortedBy(field) {
            return this.query.sort_by === field;
        },
    },
};
</script>
