<template>
    <span v-show="count" class="unread-badge badge badge-danger" v-html="count"></span>
</template>

<script>
export default {
    name: 'unread-count',
    computed: {
        count() {
            return window.User.unread_notification_count + window.User.unread_message_count;
        },
    },
};
</script>

<style>
.unread-badge {
    position: absolute;
    top: 0;
    left: -0.5rem;
    font-family: sans-serif;
}
</style>
