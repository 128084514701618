<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9">
                <div class="card">
                    <div class="card-header text-center">Suggest New Venue</div>

                    <div class="card-body">
                        <form @submit.prevent="onSubmit" @keydown="form.clearErrors($event.target.name)">
                            <h6>Contact Info</h6>
                            <div class="form-group" :class="{ 'has-danger': form.errors.get('name') }">
                                <label for="name">Name *</label>
                                <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('name') }"
                                    v-model="form.name"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('name')"
                                    v-text="form.errors.get('name')"
                                ></span>
                            </div>
                            <div class="form-group" :class="{ 'has-danger': form.errors.get('email') }">
                                <label for="name">Email *</label>
                                <input
                                    type="text"
                                    name="email"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('email') }"
                                    v-model="form.email"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('email')"
                                    v-text="form.errors.get('email')"
                                ></span>
                            </div>
                            <div class="form-group" :class="{ 'has-danger': form.errors.get('phone') }">
                                <label for="name">Phone Number</label>
                                <input
                                    type="text"
                                    name="user_phone"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('user_phone') }"
                                    v-model="form.user_phone"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('user_phone')"
                                    v-text="form.errors.get('phone')"
                                ></span>
                            </div>

                            <hr />

                            <h6>Venue Details</h6>
                            <div class="form-group" :class="{ 'has-danger': form.errors.get('title') }">
                                <label for="name">Venue Name *</label>
                                <input
                                    type="text"
                                    name="title"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('title') }"
                                    v-model="form.title"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('title')"
                                    v-text="form.errors.get('title')"
                                ></span>
                            </div>

                            <address-fields
                                @change="form.clearErrors()"
                                :form="form"
                                :visibleCountriesOnly="true"
                                :requiredFields="['postal_code']"
                            >
                            </address-fields>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('phone') }">
                                <label for="name">Phone #</label>
                                <input
                                    type="text"
                                    name="phone"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('phone') }"
                                    v-model="form.phone"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('phone')"
                                    v-text="form.errors.get('phone')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('website') }">
                                <label for="name">Website</label>
                                <input
                                    type="text"
                                    name="website"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('website') }"
                                    v-model="form.website"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('website')"
                                    v-text="form.errors.get('website')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('facebook') }">
                                <label for="facebook">Facebook</label>
                                <input
                                    type="text"
                                    name="facebook"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('facebook') }"
                                    v-model="form.facebook"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('facebook')"
                                    v-text="form.errors.get('facebook')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('instagram') }">
                                <label for="instagram">Instagram</label>
                                <input
                                    type="text"
                                    name="instagram"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('instagram') }"
                                    v-model="form.instagram"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('instagram')"
                                    v-text="form.errors.get('instagram')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('twitter') }">
                                <label for="twitter">X</label>
                                <input
                                    type="text"
                                    name="twitter"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('twitter') }"
                                    v-model="form.twitter"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('twitter')"
                                    v-text="form.errors.get('twitter')"
                                ></span>
                            </div>

                            <!-- Genres Here -->

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('age') }">
                                <label for="age"
                                    >Age <small>(If the venue welcomes all ages, put "0" as the age.)</small></label
                                >
                                <input
                                    type="text"
                                    name="age"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('age') }"
                                    v-model="form.age"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('age')"
                                    v-text="form.errors.get('age')"
                                ></span>
                            </div>

                            <div class="form-group row" :class="{ 'has-danger': form.errors.get('genres') }">
                                <div class="col-12">
                                    <label for="genres">Genres</label>
                                    <genre-select v-model="form.genres"></genre-select>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('genres')"
                                        v-text="form.errors.get('genres')"
                                    ></span>
                                </div>
                            </div>

                            <div
                                class="form-group row"
                                :class="{ 'has-danger': form.errors.get('blacklisted_genres') }"
                            >
                                <div class="col-12">
                                    <label for="genres">Excluded Genres</label>
                                    <genre-select v-model="form.blacklisted_genres"></genre-select>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('blacklisted_genres')"
                                        v-text="form.errors.get('blacklisted_genres')"
                                    ></span>
                                </div>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('capacity') }">
                                <label for="capacity">Capacity</label>
                                <input
                                    type="text"
                                    name="capacity"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('capacity') }"
                                    v-model="form.capacity"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('capacity')"
                                    v-text="form.errors.get('capacity')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('capacity_extra') }">
                                <label for="capacity_extra">Additional Capacity Info</label>
                                <input
                                    type="text"
                                    name="capacity_extra"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('capacity_extra') }"
                                    v-model="form.capacity_extra"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('capacity_extra')"
                                    v-text="form.errors.get('capacity_extra')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('booker_name') }">
                                <label for="booker_name">Booker Name</label>
                                <input
                                    type="text"
                                    name="booker_name"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('booker_name') }"
                                    v-model="form.booker_name"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('booker_name')"
                                    v-text="form.errors.get('booker_name')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('booker_email') }">
                                <label for="name">Booker Email</label>
                                <input
                                    type="text"
                                    name="booker_email"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('booker_email') }"
                                    v-model="form.booker_email"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('booker_email')"
                                    v-text="form.errors.get('booker_email')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('booker_phone') }">
                                <label for="name">Booker Phone #</label>
                                <input
                                    type="text"
                                    name="booker_phone"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('booker_phone') }"
                                    v-model="form.booker_phone"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('booker_phone')"
                                    v-text="form.errors.get('booker_phone')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('booker_link') }">
                                <label for="booker_link">Booker Website/Link</label>
                                <input
                                    name="booker_link"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('booker_link') }"
                                    v-model="form.booker_link"
                                />
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('booker_link')"
                                    v-text="form.errors.get('booker_link')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('sound_system') }">
                                <label for="sound_system">Does This Venue Have a Sound System?</label>
                                <select
                                    name="sound_system"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('sound_system') }"
                                    v-model="form.sound_system"
                                >
                                    <option value="unknown"></option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('sound_system')"
                                    v-text="form.errors.get('sound_system')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('booking_tips') }">
                                <label for="booking_tips">Booking Tips/Sound System</label>
                                <textarea
                                    type="text"
                                    name="booking_tips"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('booker_notes') }"
                                    v-model="form.booker_notes"
                                ></textarea>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('booker_notes')"
                                    v-text="form.errors.get('booker_notes')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('description') }">
                                <label for="name">Description, Show Nights, Other Info</label>
                                <textarea
                                    type="text"
                                    name="desc"
                                    class="form-control"
                                    :class="{ 'form-control-danger': form.errors.get('description') }"
                                    v-model="form.description"
                                ></textarea>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('description')"
                                    v-text="form.errors.get('description')"
                                ></span>
                            </div>

                            <div class="form-group" :class="{ 'has-danger': form.errors.get('is_festival') }">
                                <label for="is_festival">
                                    <span class="mb-2">Is this a music festival?</span>
                                    <input
                                        type="checkbox"
                                        name="is_festival"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('is_festival') }"
                                        v-model="form.is_festival"
                                    />
                                </label>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('is_festival')"
                                    v-text="form.errors.get('is_festival')"
                                ></span>
                            </div>

                            <div
                                class="form-group"
                                :class="{ 'has-danger': form.errors.get('festival_submission_start_date') }"
                                v-if="form.is_festival"
                            >
                                <label for="festival_submission_start_date">What day does festival begin taking applications?</label>
                                <flat-pickr
                                        class="w-100 form-control"
                                        :config="config"
                                        placeholder="Select Festival Submission Start Date"
                                        v-model="form.festival_submission_start_date"
                                        name="festival_submission_start_date"
                                        @input="form.clearErrors()"
                                        v-on:change="form.clearErrors('change')"
                                    >
                                </flat-pickr>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('festival_submission_start_date')"
                                    v-text="form.errors.get('festival_submission_start_date')"
                                ></span>
                            </div>

                            <div
                                class="form-group"
                                :class="{ 'has-danger': form.errors.get('festival_submission_end_date') }"
                                v-if="form.is_festival"
                            >
                                <label for="festival_submission_end_date">What day does festival end taking applications?</label>
                                <flat-pickr
                                        class="w-100 form-control"
                                        :config="config"
                                        placeholder="Select Festival Submission End Date"
                                        v-model="form.festival_submission_end_date"
                                        name="festival_submission_start_date"
                                        @input="form.clearErrors()"
                                        v-on:change="form.clearErrors('change')"
                                    >
                                </flat-pickr>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('festival_submission_end_date')"
                                    v-text="form.errors.get('festival_submission_end_date')"
                                ></span>
                            </div>

                            <div
                                class="form-group"
                                :class="{ 'has-danger': form.errors.get('festival_start_date') }"
                                v-if="form.is_festival"
                            >
                                <label for="festival_start_date">What day does the festival start?</label>
                                <flat-pickr
                                        class="w-100 form-control"
                                        :config="config"
                                        placeholder="Select Festival Start Date"
                                        v-model="form.festival_start_date"
                                        name="festival_start_date"
                                        @input="form.clearErrors()"
                                        v-on:change="form.clearErrors('change')"
                                    >
                                </flat-pickr>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('festival_start_date')"
                                    v-text="form.errors.get('festival_start_date')"
                                ></span>
                            </div>

                            <div
                                class="form-group"
                                :class="{ 'has-danger': form.errors.get('festival_end_date') }"
                                v-if="form.is_festival"
                            >
                                <label for="festival_end_date">What day does the festival end?</label>
                                <flat-pickr
                                        class="w-100 form-control"
                                        :config="config"
                                        placeholder="Select Festival End Date"
                                        v-model="form.festival_end_date"
                                        name="festival_end_date"
                                        @input="form.clearErrors()"
                                        v-on:change="form.clearErrors('change')"
                                    >
                                </flat-pickr>
                                <span
                                    class="form-control-feedback"
                                    v-show="form.errors.has('festival_end_date')"
                                    v-text="form.errors.get('festival_end_date')"
                                ></span>
                            </div>

                            <div class="form-group row">
                                <div class="col-4 mx-auto mt-3">
                                    <btn-state :state="form.state" btnClass="btn-block btn-primary"> Send </btn-state>
                                </div>
                            </div>

                            <div class="alert alert-success text-center" v-if="form.state === 'success'">
                                <span>Venue suggestion sent to admin for review.</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import addressFields from '../../components/addressFields.vue';
import btnState from '../../components/btnState.vue';
import news from '../../components/news.vue';
import Form from '../../form/form.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    components: {
        news,
        btnState,
        addressFields,
        flatPickr,
    },
    data() {
        return {
            response: {},
            form: new Form({
                name: window.User.full_name || '',
                email: window.User.email || '',
                user_phone: null,

                title: '',
                description: '',
                country_id: 1,
                state_id: '',
                city_id: '',
                custom_city: '',
                postal_code: '',
                address: '',
                booker_name: '',
                booker_email: '',
                booker_phone: '',
                booker_notes: '',
                booker_link: '',
                phone: '',
                genres: [],
                blacklisted_genres: [],
                website: '',
                age: '',
                days_open: '',
                capacity: '',
                capacity_extra: '',
                twitter: '',
                facebook: '',
                instagram: '',
                is_festival: false,
                festival_submission_start_date: '',
                festival_submission_end_date: '',
                festival_start_date: '',
                festival_end_date: '',
                sound_system: 'unknown',
            }),
        };
    },
    methods: {
        updateGenres(val) {
            this.form.genres = val;
        },
        updateBlacklistGenres(val) {
            this.form.blacklisted_genres = val;
        },
        onSubmit() {
            var $v = this;
            this.form
                .post('/api/venues')
                .then(function (response) {
                    $v.response = response.data;
                })
                .catch(function (error) {
                    $v.response = error.data;
                });
        },
    },
};
</script>
