<template>
    <div class="card">
        <div class="card-header">
            <h4 class="m-0">Edit Video</h4>
        </div>
        <form class="card-body" @submit.prevent="submit" @keydown="form.clearErrors($event.target.name)">
            <div class="form-group">
                <img class="img-fluid" :src="form.thumbnail + '?w=300&h=300&fit=crop'" />
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('title') }">
                <label for="title">Title</label>
                <input
                    :class="{ 'form-control-danger': form.errors.get('title') }"
                    name="title"
                    v-model="form.title"
                    type="text"
                    class="form-control"
                />
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('title')"
                    v-text="form.errors.get('title')"
                ></span>
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('venue_uuid') }">
                <label for="venue_uuid">Venue</label>
                <venue-select v-model="form.venue_uuid"></venue-select>
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('venue_uuid')"
                    v-text="form.errors.get('venue_uuid')"
                ></span>
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('description') }">
                <label for="description">Description</label>
                <textarea
                    :class="{ 'form-control-danger': form.errors.get('description') }"
                    name="description"
                    v-model="form.description"
                    class="form-control"
                />
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('description')"
                    v-text="form.errors.get('description')"
                ></span>
            </div>

            <div class="form-group d-flex align-items-center">
                <btn-state class="btn btn-primary" :state="form.state"> Update </btn-state>
                <a href="/media-videos" class="pl-2 btn-link">
                    <span v-if="form.state === 'success'"> done </span>
                    <span v-else> cancel </span>
                </a>
            </div>
        </form>
    </div>
</template>

<script>
import Form from '../../form/form.js';
import btnState from '../../components/btnState.vue';

export default {
    components: { btnState },

    props: ['uuid'],

    data() {
        return {
            form: new Form(),
        };
    },

    created() {
        this.fetchVideo();
    },

    methods: {
        updateVenue(venue) {
            this.form.venue_uuid = venue;
        },
        fetchVideo() {
            axios.get('/api/videos/' + this.uuid).then(res => {
                this.form = new Form(Object.assign({ venue_uuid: '' }, res.data.data));
            });
        },

        submit() {
            this.form.put('/api/videos/' + this.uuid);
        },
    },
};
</script>
