<template>
    <div class="card">
        <div class="card-header">
            <h4 class="m-0">Add Video</h4>
        </div>
        <form class="card-body" @submit.prevent="submit" @keydown="form.clearErrors($event.target.name)">
            <div class="form-group" :class="{ 'has-danger': form.errors.get('name') }">
                <label for="title">Title</label>
                <input
                    :class="{ 'form-control-danger': form.errors.get('title') }"
                    name="title"
                    v-model="form.title"
                    type="text"
                    class="form-control"
                />
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('title')"
                    v-text="form.errors.get('title')"
                ></span>
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('venue_uuid') }">
                <label for="venue_uuid">Venue</label>
                <venue-select v-model="form.venue_uuid"></venue-select>
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('venue_uuid')"
                    v-text="form.errors.get('venue_uuid')"
                ></span>
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('description') }">
                <label for="description">Description</label>
                <textarea
                    :class="{ 'form-control-danger': form.errors.get('description') }"
                    name="description"
                    v-model="form.description"
                    class="form-control"
                />
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('description')"
                    v-text="form.errors.get('description')"
                ></span>
            </div>

            <div class="form-group" :class="{ 'has-danger': form.errors.get('url') }">
                <label for="url">Url</label>
                <input
                    :class="{ 'form-control-danger': form.errors.get('url') }"
                    name="url"
                    v-model="form.url"
                    type="text"
                    class="form-control"
                />
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('url')"
                    v-text="form.errors.get('url')"
                ></span>
                <small class="form-text text-muted">We support Youtube and Vimeo videos</small>
                <small class="form-text text-muted"><b>Examples:</b></small>
                <small class="form-text text-muted">http://vimeo.com/23025122</small>
                <small class="form-text text-muted">http://www.youtube.com/watch?v=NisCkxU544c</small>
            </div>

            <div class="form-group d-flex align-items-center">
                <btn-state class="btn btn-primary" :state="form.state"> Create </btn-state>
                <a href="/media-videos" class="pl-2 btn-link">cancel</a>
            </div>
        </form>
    </div>
</template>

<script>
import Form from '../../form/form.js';
import Query from '../../query/query.js';
import btnState from '../../components/btnState.vue';

export default {
    components: { btnState },

    data() {
        return {
            isLoadingOptions: false,
            query: new Query({
                venue: '',
            }),
            form: new Form({
                title: '',
                venue_uuid: '',
                description: '',
                url: '',
            }),
        };
    },

    created() {
        this.query.parse(location.search);

        this.form.venue_uuid = this.query.venue;
    },

    methods: {
        submit() {
            this.form.post('/api/videos').then(() => {
                window.location = '/media-videos';
            });
        },
    },
};
</script>
