<template>
    <div>
        <div class="row">
            <div class="form-group col-4 pr-0 pr-sm-2">
                <select
                    @change.stop="handleCountryChange"
                    v-model="query.country"
                    :class="[errors.has('state') ? 'is-invalid' : '', 'form-control']"
                >
                    <option value="">All Countries</option>
                    <option v-for="country in countries" :value="country.id" v-text="country.name"></option>
                </select>
            </div>
            <div class="form-group col-4">
                <select
                    :disabled="!query.country"
                    @change.stop="handleStateChange"
                    v-model="query.state"
                    :class="[errors.has('state') ? 'border-danger' : '', 'form-control']"
                >
                    <option value="">All States</option>
                    <option v-for="state in states" :value="state.id" v-text="state.name"></option>
                </select>
            </div>
            <div class="form-group col-4 pl-0 pl-sm-2">
                <select
                    :disabled="!query.state"
                    @change.stop="handleCityChange"
                    v-model="query.city"
                    :class="[errors.has('state') ? 'border-danger' : '', 'form-control']"
                >
                    <option value="">All Cities</option>
                    <option v-for="city in cities" :value="city.id" v-text="city.name"></option>
                </select>
            </div>
        </div>

        <!-- Zip and Radius -->
        <div class="row">
            <div class="form-group col-6" :class="{ 'has-danger': errors.has('zip') }">
                <input
                    @input.stop="handleZipChange"
                    type="text"
                    v-model="query.zip"
                    class="form-control"
                    :class="{ 'form-control-danger': errors.has('zip') }"
                    placeholder="Zip"
                />
                <span class="form-control-feedback" v-show="errors.has('zip')" v-text="errors.get('zip')"></span>
            </div>
            <div class="form-group col-6">
                <select :disabled="!(query.city || query.zip.length >= 5)" v-model="query.radius" class="form-control">
                    <option value="">No Radius/Exact</option>
                    <option value="10">10 miles</option>
                    <option value="25">25 miles</option>
                    <option value="50">50 miles</option>
                    <option value="100">100 miles</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import Errors from '../form/errors.js';
export default {
    name: 'address-filters',

    props: {
        countries_with: {
            type: String,
            default: '',
        },
        active_countries: {
            type: Boolean,
            default: false,
        },
        query: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            errors: {},
            countries: [],
            states: [],
            cities: [],
        };
    },
    created() {
        this.errors = new Errors();

        this.fetchCountries();

        // this.query.country = 1 // USA

        if (this.query.country) {
            this.fetchStates();
        }

        if (this.query.state) {
            this.fetchCities();
        }
    },

    methods: {
        handleZipChange(e) {
            this.errors.clear();
            const length = this.query.zip.length;
            var regex = new RegExp('^([A-Za-z0-9-]|\\s)+$');
            if (!regex.test(this.query.zip)) {
                var zip = this.query.zip.replace(e.data, '');
                this.query.zip = zip;
            }
            if (length === 10) {
                this.query.state = '';
                this.query.city = '';
                this.$emit('input', e);
            } else if (length >= 10) {
                this.query.zip = this.query.zip.substr(0, 10);
            }
        },

        handleCountryChange(e) {
            this.query.country = e.target.value;
            this.query.state = '';
            this.query.city = '';
            this.updateNamesInQuery();
            this.fetchStates();
        },

        handleStateChange(e) {
            this.query.state = e.target.value;
            this.query.city = '';
            this.updateNamesInQuery();
            this.fetchCities();
        },

        handleCityChange(e) {
            this.query.city = e.target.value;
            this.updateNamesInQuery();
        },

        updateNamesInQuery() {
            const byId = id => loc => loc.id == id;
            const getName = obj => (obj ? obj.name : '');
            this.query.country_name = getName(this.countries.find(byId(this.query.country)));
            this.query.state_name = getName(this.states.find(byId(this.query.state)));
            this.query.city_name = getName(this.cities.find(byId(this.query.city)));
            this.errors.clear();
            setTimeout(() => {
                this.$emit('input');
            });
        },

        fetchCountries() {
            var $v = this;
            return axios
                .get('/api/countries' + '?with=' + this.countries_with)
                .then(function (response) {
                    $v.countries = response.data.data;
                })
                .catch(function (error) {
                    $v.locationFetchFailed = error.data;
                });
        },

        fetchStates() {
            this.cities = [];
            this.states = [];
            var $v = this;
            axios
                .get('/api/states?country_id=' + this.query.country)
                .then(function (response) {
                    $v.states = response.data.data;
                })
                .catch(function (error) {
                    $v.locationFetchFailed = error.data;
                });
        },

        fetchCities() {
            this.cities = [];
            this.query.zip = '';
            var $v = this;
            axios
                .get('/api/cities?state_id=' + this.query.state)
                .then(function (response) {
                    $v.cities = response.data.data;
                })
                .catch(function (error) {
                    $v.locationFetchFailed = error.data;
                });
        },

        clearErrors() {
            this.errors.clear();
        }
    },
};
</script>
