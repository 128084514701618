<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9">
                <div class="card mb-2">
                    <div class="card-header d-flex justify-content-between">
                        <h4 class="availTitle">Show Availabilities</h4>
                        <a href="/show-availabilities/create" class="ml-1 btn btn-sm btn-secondary align-self-end">
                            <i class="fa fa-plus pr-1"></i>
                            Add
                        </a>
                    </div>
                    <div class="table-responsive-sm" v-if="venueAvailabilities.length > 0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th class="d-none d-md-table-cell">Created:</th>
                                    <th class="d-none d-md-table-cell">Submitted:</th>
                                    <th>Approved:</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(avail, key) in venueAvailabilities">
                                    <th scope="row">
                                        {{ key + 1 }}
                                    </th>
                                    <th>
                                        <a
                                            class="text-truncate d-block d-md-none"
                                            :href="'/show-availabilities/' + avail.slug + '/' + avail.uuid"
                                            id="listingTitle"
                                        >
                                            {{  truncate(avail.title, 15, '...') }}
                                        </a>
                                        <a
                                            class="d-none d-md-block"
                                            :href="'/show-availabilities/' + avail.slug + '/' + avail.uuid"
                                            id="listingTitle"
                                        >
                                            {{ avail.title }}
                                        </a>
                                    </th>
                                    <th class="d-none d-md-table-cell">
                                        <small class="ml-auto">
                                            {{ fromUTC(avail.created_at, 'YYYY-MM-DD')}}
                                        </small>
                                    </th>
                                    <th class="d-none d-md-table-cell">
                                        <small> {{ avail.submitted === true ? 'Yes' : 'No' }} </small>
                                    </th>
                                    <th>
                                        <small> {{ avail.approved !== '' ? 'Yes' : 'Pending' }} </small>
                                    </th>
                                    <th>
                                        <a :href="'/show-availabilities/' + avail.uuid + '/edit'">
                                            <i class="fa fa-cog"></i>
                                        </a>
                                    </th>
                                    <th>
                                        <a href="#" @click="deleteRequest(avail)">
                                            <i class="btn btn-sm btn-danger fa fa-trash"></i>
                                        </a>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination
                        v-if="venueAvailabilities.length > 0"
                        :pagination="pagination"
                        :loading="initialLoading"
                        @pageChange="updatePage"
                    ></pagination>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import news from '../../components/news.vue';
import pagination from '../../components/pagination.vue';
import Query from '../../query/query.js';
import {
    fromUTC,
    truncate,
} from "../../filters/index.js";

export default {
    components: { news, pagination },
    data: function () {
        return {
            query: new Query({
                sort_order: 'descending',
                page: '',
                date: 'all',
                manage: 'true',
                user_uuid: window.User.uuid,
            }),
            initialLoading: true,
            pagination: {
                current_page: 1,
            },
            venueAvailabilities: [],
        };
    },
    computed: {
        fetchUrl() {
            return '/api/venue-availabilities?' + this.query.toString();
        },
    },
    created() {
        this.fetchVenueAvails();
    },
    methods: {
        fromUTC,
        truncate,
        updatePage(number) {
            this.pagination.current_page = number;
            this.query.page = number;
            this.handleQueryUpdate();
        },
        handleQueryUpdate() {
            this.fetchVenueAvails();
            this.query.push();
            if (this.query.save_search) {
                localStorage.iotmSearch = location.search;
            }
        },
        deleteRequest(value, index) {
            axios.delete('/api/venue-availabilities/' + value.uuid).then(() => {
                this.initialLoading = true;
                swal('Deleted!', 'Show Availability Deleted', 'success');
                this.fetchVenueAvails();
            });
        },
        fetchVenueAvails() {
            axios.get(this.fetchUrl).then(response => {
                this.initialLoading = false;
                this.pagination = response.data.meta.pagination;
                this.venueAvailabilities = response.data.data;
            });
        },
    },
};
</script>
