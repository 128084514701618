/* global swal */
import "lodash";
import $ from "jquery";
window.jQuery = window.$ = $;
import Popper from "popper.js";
window.Popper = Popper;
import "bootstrap";
import swal from "sweetalert";

import Vue from "vue";
window.Vue = Vue;

import axios from "axios";
window.axios = axios;

import Trix from "trix";
Trix.config.textAttributes.underline = {
    style: { textDecoration: "underline" },
    inheritable: true,
    parser: function (element) {
        var style = window.getComputedStyle(element);
        return style.textDecoration === "underline";
    },
};

// window.Pusher = require('pusher-js')

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers["X-CSRF-TOKEN"] = window.app.csrfToken;
    if (config.method === "delete" && config.headers["noConfirm"]) {
        return config;
    } else if (config.method === "delete") {
        return new Promise((resolve, reject) => {
            swal({
                title: "Are you sure?",
                text: "You will not be able to undo this!",
                icon: "warning",
                buttons: [true, "Yes, delete it!"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    resolve(config);
                }
            });
        });
    } else {
        return config;
    }
});

axios.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                window.location = "/login?sessionExpired=true";
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": window.app.csrfToken,
    },
});

// import Echo from 'laravel-echo'

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: '5b110c1268930c0231ce'
// })
//

import { createBrowserHistory } from "history";

window.History = createBrowserHistory({
    hashType: "slash",
});

// Path: resources/js/areyousure.js
/*
  <a href="posts/2" data-method="delete"> <---- We want to send an HTTP DELETE request

  - Or, request confirmation in the process -

  <a href="posts/2" data-method="delete" data-confirm="Are you sure?">
*/

(function () {
    var areyousure = {
        initialize: function () {
            this.registerEvents();
        },

        registerEvents: function () {
            $("body").on("click", "a[data-method]", this.handleMethod);
        },

        handleMethod: function (e) {
            var link = $(this);
            var httpMethod = link.data("method").toUpperCase();
            var form;

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ($.inArray(httpMethod, ["PUT", "DELETE"]) === -1) {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            areyousure
                .verifyConfirm(link)
                .then((answer) => {
                    if (answer) {
                        form = areyousure.createForm(link);
                        form.submit();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    swal({
                        title: "Not Deleted",
                        text: "An error occurred while deleting",
                        icon: "error",
                    });
                });

            e.preventDefault();
        },

        verifyConfirm: function (link) {
            var text = link.attr("data-confirm");

            return new Promise((resolve, reject) => {
                swal({
                    title: "Are you sure?",
                    text: "You will not be able to undo this!",
                    icon: "warning",
                    buttons: [true, "Yes, delete it!"],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        resolve(true);
                    }
                });
            });
        },

        createForm: function (link) {
            var form = $("<form>", {
                method: "POST",
                action: link.attr("href"),
            });

            var token = $("<input>", {
                name: "_token",
                type: "hidden",
                value: window.app.csrfToken,
            });

            var hiddenInput = $("<input>", {
                name: "_method",
                type: "hidden",
                value: link.data("method"),
            });

            return form.append(token, hiddenInput).appendTo("body");
        },
    };

    areyousure.initialize();
})();

var popupSize = {
    width: 780,
    height: 550,
};

$(document).on("click", ".social-button", function (e) {
    var verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
        horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2);

    var popup = window.open(
        $(this).prop("href"),
        "social",
        "width=" +
            popupSize.width +
            ",height=" +
            popupSize.height +
            ",left=" +
            verticalPos +
            ",top=" +
            horisontalPos +
            ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1"
    );

    if (popup) {
        popup.focus();
        e.preventDefault();
    }
});
