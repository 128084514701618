<template>
    <div class="daySelect">
        <v-select
            clearable
            multiple
            filterable
            @update:modelValue="updateValue($event)"
            v-model="localValue"
            placeholder="Select Music Days/Nights"
            :append-to-body="true"
            :options="days"
        />
    </div>
</template>

<script setup>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { ref, watch } from 'vue';

const props = defineProps({
    size: {
        type: String,
        default: '',
    },

    modelValue: {
        required: false,
        type: Array,
        default: () => [],
    },
});

const localValue = ref([...props.modelValue]);

const emit = defineEmits([
    'update:modelValue',
]);

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const updateValue = (val) => {
    emit('update:modelValue', val);
};

watch(() => props.modelValue, (newValue) => {
    localValue.value = [...newValue];
}, { deep: true });

</script>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
};
</script>
