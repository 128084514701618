<template>
    <form @submit.prevent="$emit('queryUpdate')" @change="handleInput" class="mb-2">
        <!-- Show Search Only Default -->
        <div class="form-group">
            <input @input="query.sort_by = 'relevance'" v-model="query.q" class="form-control"
                placeholder="Search name, city, state" />
        </div>

        <!-- Location -->
        <address-filters
            @input="$emit('queryUpdate')"
            countries_with="venues"
            :query="query"
            ref="addressFilters"
        />

        <!-- Genre (Free User) -->
        <div class="row">
            <div class="col-md-6">
                <div v-if="!isPremium" class="form-group">
                    <select v-model="query.genre" class="form-control">
                        <option value="">All Genres</option>
                        <option v-for="g in genres" :value="g.id" v-text="g.genre"></option>
                    </select>
                </div>

                <!-- Genres (Premium) -->
                <div v-else class="form-group">
                    <genre-select
                        :isFilter="true"
                        @update:modelValue="updateGenres"
                        v-model="genreIntegers"
                    />
                </div>
            </div>

            <!-- Sort -->
            <div class="form-group col-md-6 d-flex flex-column">
                <div class="d-flex">
                    <a v-show="query.sort_by !== 'relevance'" @click.prevent="$emit('toggleOrder')"
                        class="d-flex align-items-center mr-1 btn btn-secondary">
                        <i v-if="query.sort_order === 'ascending'" class="fa fa-sort-amount-asc"></i>
                        <i v-else class="fa fa-sort-amount-desc"></i>
                        Sort
                    </a>

                    <select v-model="query.sort_by" class="form-control">
                        <option value="title">Name</option>
                        <option value="popularity">Rating</option>
                        <option value="created_at">Date Added</option>
                        <option value="capacity">Capacity</option>
                        <option value="age">Age</option>
                        <option v-if="showDistance" value="distance">Distance</option>
                        <option v-if="query.q" value="relevance">Relevance</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row" v-if="isLogged">
            <div class="col-md-6 mb-2 mt-2">
                <div class="form-check">
                    <input @click="$emit('queryUpdate')" class="form-check-input" id="onlyShowFollowedCheck" type="checkbox"
                        v-model="query.only_show_followed" />
                    <label class="form-check-label" for="onlyShowFollowedCheck"> Only Show Followed</label>
                </div>
            </div>
        </div>

        <!-- Filters -->
        <div v-show="showingFilters || errors.has('min_capacity') || errors.has('max_capacity')">
            <a class="btn btn-primary text-white mb-2" @click="$emit('hideFilters')">
                <i class="fa fa-minus"></i> Filters
            </a>

            <div v-if="isPremium">
                <div class="row">
                    <div class="form-group col-md-4">
                        <select v-model="query.song_types" class="form-control">
                            <option value="">All Song Types</option>
                            <option value="Originals Only">Originals Only</option>
                            <option value="Covers Only">Covers Only</option>
                        </select>
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <select @change="emitQueryUpdate()" v-model="query.min_capacity" class="form-control"
                            :class="{ 'border-danger': errors.has('min_capacity') }">
                            <option value="" disabled>Min Capacity</option>
                            <option value="0">0</option>
                            <option v-for="cap in capacities" :value="cap">{{ cap }}</option>
                        </select>
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <select @change="emitQueryUpdate()" v-model="query.max_capacity" class="form-control"
                            :class="{ 'border-danger': errors.has('max_capacity') }">
                            <option value="" disabled>Max Capacity</option>
                            <option v-for="cap in capacities" :value="cap">{{ cap }}</option>
                            <option value="unlimited">Unlimited</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-6">
                        <day-select @update:modelValue="updateDays" v-model="query.days"></day-select>
                    </div>
                    <div class="form-group col-sm-6">
                        <category-select
                            v-model="categoryIntegers"
                            @category-input="updateCategories">
                        </category-select>
                    </div>
                </div>

                <div class="form-check" :show="isPremium">
                    <input @click="handleRememberCheck" class="form-check-input" id="saveSearchCheck" type="checkbox"
                        v-model="query.save_search" />
                    <label class="form-check-label" for="saveSearchCheck"> Remember Search </label>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
/* global bus location */
import Errors from '../../form/errors.js';
import categorySelect from '../categorySelect.vue';
import addressFilters from '../addressFilters.vue';

export default {
    name: 'venues-filter',

    components: { addressFilters, categorySelect },

    props: {
        showingFilters: {
            type: Boolean,
            default: false,
        },

        query: {
            type: Object,
            required: true,
        },

        showDistance: {
            type: Boolean,
            default: false,
        },

        isPremium: {
            type: Boolean,
            default: false,
        },

        isLogged: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            capacities: [100, 250, 400, 600, 800, 1000, 1250, 1500, 2000],
            errors: {},
            genres: [],
        };
    },

    created() {
        this.errors = new Errors();

        this.fetchGenres();
    },

    computed: {
        // must provide integers not strings
        genreIntegers() {
            return this.query.genres.map(g => parseInt(g));
        },

        // must provide integers not strings
        categoryIntegers() {
            return this.query.categories.map(g => parseInt(g));
        },
    },

    methods: {
        // needed for non premium genre single select
        fetchGenres() {
            var $v = this;
            return axios
                .get('/api/genres')
                .then(function (response) {
                    $v.genres = response.data.data;
                })
                .catch(function (error) {
                    $v.genreFetchFailed = error.data;
                });
        },

        updateGenres(genres) {
            this.query.genres = genres;
            this.$emit('queryUpdate', this.query);
        },

        updateCategories(categories) {
            this.query.categories = categories;
            this.$emit('queryUpdate', this.query);
        },

        updateDays(days) {
            this.query.days = days;
            this.$emit('queryUpdate', this.query);
        },

        handleRememberCheck(event) {
            if (!event.target.checked) {
                localStorage.iotmSearch = '';
            }
        },

        handleInput(e) {
            setTimeout(() => {
                this.$emit('queryUpdate', this.query);
            }, 100);
        },

        clearErrors() {
            this.errors.clear();

            this.$refs.addressFilters.clearErrors();
        },
    },
};
</script>
