<template>
    <button class="btn" @click="$emit('click')" :disabled="isDisabled" :class="btnClass + ' ' + stateClass">
        <span v-show="stateClass == 'btn-loading'">
            <i class="fa fa-pulse fa-spinner mx-auto"></i>
        </span>
        <span v-show="stateClass == 'btn-success'">
            <i class="text-white fa fa-check mx-auto"></i>
        </span>
        <span v-show="stateClass == 'btn-danger'">
            <i class="text-white fa fa-close mx-auto"></i>
        </span>
        <div v-show="stateClass != 'btn-success' && stateClass != 'btn-danger' && stateClass != 'btn-loading'">
            <slot>Save</slot>
        </div>
    </button>
</template>

<script>
export default {
    name: 'btn-state',
    data() {
        return {
            classMap: {
                processing: 'btn-loading',
                success: 'btn-success',
                error: 'btn-danger',
                none: '',
            },
        };
    },
    computed: {
        stateClass() {
            return this.classMap[this.state];
        },

        isDisabled() {
            return !!this.stateClass || this.disabled;
        },
    },
    props: {
        state: {
            default: 'none',
        },
        btnClass: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
button {
    min-width: 50px;
}
</style>
