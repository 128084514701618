<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h4 class="m-0">My Videos</h4>
            <a href="/videos/create" class="btn btn-sm btn-outline-primary"> <i class="pr-2 fa fa-plus"></i>Add </a>
        </div>
        <div class="card-body">
            <div v-if="!videos.length && !initialLoad" class="container text-center my-5 py-3 alert alert-info">
                No Videos Found
            </div>
            <div v-for="v in videos" :key="v.uuid" class="row">
                <div class="col-md-3 pb-2">
                    <img class="img-fluid" :src="v.thumbnail + '?w=300&h=300&fit=crop'" />
                </div>

                <div class="col-md-9 pb-2">
                    <div class="row">
                        <div class="col-8">
                            <p><b class="pr-2">Added:</b>{{ fromUTC(v.created_at) }}</p>
                            <p><b class="pr-2">Source:</b>{{ v.source }}</p>
                            <p v-if="v.title"><b class="pr-2">Title:</b>{{ v.title }}</p>
                            <p v-if="v.description"><b class="pr-2">Description:</b>{{ v.description }}</p>
                            <p v-if="v.venue"><b class="pr-2">Venue:</b>{{ v.venue }}</p>
                        </div>

                        <div class="col-4 text-right">
                            <a class="btn-sm btn btn-secondary" :href="'/videos/' + v.uuid + '/edit'">
                                <i class="fa fa-pencil"></i>
                            </a>
                            <a class="btn-sm btn btn-danger" @click="deleteVideo(v.uuid)">
                                <i class="text-white fa fa-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import btnState from '../../components/btnState.vue';
import {
    fromUTC,
} from "../../filters/index.js";

export default {
    components: { btnState },

    data() {
        return {
            videos: [],
            initialLoad: true,
        };
    },

    created() {
        this.fetchVideos();
    },

    methods: {
        fromUTC,
        fetchVideos() {
            axios.get('/api/videos?user=' + window.User.uuid).then(res => {
                this.videos = res.data.data;
                this.initialLoad = false;
            });
        },

        deleteVideo(id) {
            axios.delete('/api/videos/' + id).then(() => {
                this.fetchVideos();
            });
        },
    },
};
</script>
