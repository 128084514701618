<template>
    <div>
        <modal v-if="showModal">
            <template v-slot:body>
                <p class="text-center">Are you sure you want to delete this photo?</p>
                <div class="d-flex flex-row justify-content-center">
                    <button class="btn btn-danger m-1" @click.prevent="deletePhoto(activePhoto), (showModal = false)">
                        Delete
                    </button>
                    <button class="btn btn-primary m-1" @click.prevent="showModal = false">Close</button>
                </div>
            </template>
        </modal>
        <transition name="fade">
            <div class="d-flex flex-row justify-content-center mb-1" v-if="this.selected && this.photos.length > 0">
                <button type="button" class="btn btn-primary m-1" @click.prevent="setThumbnail(activePhoto)">
                    <i class="fa fa-file-image-o"></i>
                    <span class="text-white">
                        {{ type === 'userProfile' ? 'Set As Profile Picture' : 'Set Primary' }}
                    </span>
                </button>
                <button type="button" class="btn btn-danger m-1" id="show-modal" @click="showModal = true">
                    <i class="fa fa-minus-circle"></i>
                    <span class="text-white"> Delete </span>
                </button>
            </div>
        </transition>
        <transition name="fade">
            <div class="alert alert-success mt-3" role="alert" v-cloak v-if="deletedAlert">
                <button type="button" class="close" @click="deletedAlert = false">
                    <span aria-hidden="true">&times;</span>
                </button>
                <strong>Success!</strong> Photo deleted.
            </div>
        </transition>
        <transition name="fade">
            <div class="alert alert-success mt-3" role="alert" v-cloak v-if="thumbnailAlert">
                <button type="button" class="close" @click="thumbnailAlert = false">
                    <span aria-hidden="true">&times;</span>
                </button>
                {{ type === 'userProfile' ? 'Profile Picture Updated' : 'Primary Photo Updated' }}
            </div>
        </transition>
        <div class="card-deck d-flex flex-wrap justify-content-start mb-3">
            <div
                class="card rounded imageCard m-1"
                v-for="(photo, key) in photos"
                :class="{ activeImageCard: isActive(key) }"
                @click="setActive(key)"
            >
                <small class="text-center" v-if="isThumbnail(photo)"> Primary </small>
                <img class="cardImage img-thumbnail card-img-top mx-auto" :src="photo.url + '?w=300&h=300&fit=crop'" />
            </div>
        </div>
    </div>
</template>
<script>
import modal from './modal.vue';
export default {
    components: {
        modal,
    },
    props: {
        uuid: {
            required: true,
            type: String,
        },
        type: {
            required: true,
            type: String,
        },
    },
    created() {
        this.fetchPhotos();
    },
    mounted() {
        axios.get(this.baseUrl).then(response => {
            if (this.type === 'userProfile') {
                const thumbnailUrl = response.data.data.img_url;
                this.activeThumbnailUrl = thumbnailUrl;
            }
            const thumbnailUrl = response.data.data.img_url;
            this.activeThumbnailUrl = thumbnailUrl;
        });
    },
    data() {
        return {
            selected: false,
            photos: [],
            activeThumbnailUrl: '',
            thumbnailPhoto: {},
            showModal: false,
            activePhotoIndex: '',
            selectedPhoto: '',
            deletedAlert: false,
            thumbnailAlert: false,
        };
    },
    watch: {
        thumbnailAlert: function () {
            if (this.thumbnailAlert === true) {
                window.setInterval(
                    function () {
                        this.thumbnailAlert = false;
                    }.bind(this),
                    3000
                );
            }
        },
        deletedAlert: function () {
            if (this.deletedAlert === true) {
                window.setInterval(
                    function () {
                        this.deletedAlert = false;
                    }.bind(this),
                    3000
                );
            }
        },
    },
    computed: {
        activePhoto() {
            return this.photos[this.activePhotoIndex];
        },
        photoUrl() {
            switch (this.type) {
                case 'bandAvail':
                    return '/api/band-availabilities/' + this.uuid + '/photos';
                case 'venueAvail':
                    return '/api/venue-availabilities/' + this.uuid + '/photos';
                case 'classified':
                    return '/api/classifieds/' + this.uuid + '/photos';
                case 'userProfile':
                    return '/api/users/' + this.uuid + '/photos';
            }
        },
        baseUrl() {
            switch (this.type) {
                case 'bandAvail':
                    return '/api/band-availabilities/' + this.uuid;
                case 'venueAvail':
                    return '/api/venue-availabilities/' + this.uuid;
                case 'classified':
                    return '/api/classifieds/' + this.uuid;
                case 'userProfile':
                    return '/api/users/' + this.uuid;
            }
        },
    },
    methods: {
        fetchPhotos() {
            if (!this.uuid) {
                return;
            }

            axios.get(this.photoUrl).then(response => {
                this.photos = response.data.data;
                if (this.photos.length) {
                    this.$nextTick(() => {
                        this.setActive(this.photos.length - 1);
                    });
                }
            });
        },

        activeThumbnail(value) {
            if (this.activeThumbnailUrl === value.url) {
                this.thumbnailPhoto = value;
                return true;
            }
        },

        isThumbnail(value) {
            if (value === this.thumbnailPhoto) {
                return true;
            }
        },

        isActive(key) {
            if (this.activePhotoIndex === key) {
                return true;
            }
            return false;
        },

        setThumbnail() {
            axios.post(this.baseUrl + '/thumbnail/' + this.activePhoto.uuid).then(response => {
                if (response.status === 200) {
                    this.thumbnailPhoto = this.activePhoto;
                    this.thumbnailAlert = true;
                }
            });
        },

        setActive(key) {
            this.selected = true;
            this.activePhotoIndex = key;
        },

        deletePhoto(photo) {
            axios.delete('/api/photos/' + photo.uuid).then(() => {
                this.fetchPhotos();
                this.deletedAlert = true;
                this.thumbnailAlert = false;
            });
        },
    },
};
</script>

<style scoped>
.photoModal {
    max-width: 50vw;
}
</style>
