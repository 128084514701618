<template>
    <div class="container my-4 pt-2" v-if="!initialLoading">
        <modal v-if="showModal">
            <template v-slot:body>
                <p class="text-center">Are you sure you want to delete the following conversations</p>
                <ul class="list-group" v-for="selected in this.selected">
                    <li class="list-group-item d-flex flex-row justify-content-center m-1">
                        <small v-if="selected.messages.data.length">
                            {{ selected.subject }} @ {{ selected.messages.data[0].created_at }}
                        </small>
                        <small v-else>
                            {{ selected.subject }}
                        </small>
                    </li>
                </ul>
                <div class="d-flex flex-row justify-content-center">
                    <button class="btn btn-danger m-1" @click="deleteMsgs(), (showModal = false)">Delete</button>
                    <button class="btn btn-primary m-1" @click.prevent="showModal = false">Close</button>
                </div>
            </template>
        </modal>
        <div class="row d-flex flex-row flex-lg-row-reverse">
            <div class="col-lg-8 flex-column">
                <transition name="fade">
                    <div v-if="this.deleteSuccess" class="alert alert-success" role="alert">
                        <button type="button" class="close" data-dismiss="alert">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Success!</strong> Conversations deleted.
                    </div>
                </transition>
                <span v-if="this.view === 'create' || this.view === 'show'">
                    <transition name="component-fade" mode="out-in">
                        <create
                            v-if="view == 'create'"
                            v-bind:selected="selectedConversation"
                            @sent="fetchData"
                            @close="view = ''"
                        />
                    </transition>
                    <transition name="component-fade" mode="out-in">
                        <show
                            v-if="view == 'show'"
                            v-bind:selected="selectedConversation"
                            @close="clearSelected"
                        />
                    </transition>
                </span>
            </div>
            <div class="col-lg-4 flex-column">
                <div class="card bg-primary">
                    <div class="card-body text-white text-center">Inbox</div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-link">
                            <input class="form-check" :checked="this.selected.length && this.selectedAll" @click="selectAll"
                                type="checkbox" />
                        </button>
                        <button
                            class="btn btn-outline-primary"
                            @click="setView('create'), $emit('selectCreate')"
                        >
                            <i class="fa fa-envelope"></i>
                            Create
                        </button>
                        <button
                            class="btn btn-outline-danger ml-auto"
                            v-bind:disabled="this.selected[0] === undefined"
                            @click="showModal = true"
                        >
                            <span>Delete</span>
                        </button>
                    </div>
                    <div class="card-content" :class="[this.messages[3] ? '' : 'messageList']">
                        <ul class="list-group list-group-flush">
                            <div v-if="this.messages[0] === undefined" class="text-center">
                                <div class="alert alert-info mb-0" role="alert">
                                    <strong>No Messages</strong>
                                </div>
                            </div>
                            <li v-for="(message, key) in messages"
                                class="list-group-item d-flex flex-row justify-content-start">
                                <div>
                                    <input
                                        type="checkbox"
                                        id="cbox"
                                        v-bind:checked="isChecked(message)"
                                        value="first_checkbox"
                                        @click.stop="view = 'show'; selectMsgs(message)"
                                        class="mr-2"
                                    />
                                    <a href="#" @click.self="selectMessage(message, key)"
                                        :class="[messages[key].read === true ? 'text-muted' : 'font-weight-bold']">
                                        {{ truncate(message.subject, 30, '...') }}
                                    </a>
                                    <div class="btn-group" v-if="selectedConversation === message">
                                        <button type="button" class="text-muted btn btn-link btn-sm dropdown-toggle"
                                            data-toggle="dropdown">
                                            <i class="text-muted fa fa-cog"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" @click.prevent="toggleReadStatus(message, key)"
                                                href="#">Mark Read/Unread</a>
                                            <button class="dropdown-item btn btn-link" @click="showModal = true">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <a v-bind:href="profileUrl(message)" class="ml-auto">
                                    <small :class="[messages[key].read === true ? 'text-muted' : 'font-weight-bold']">
                                        <span v-if="user.username == message.participants.data[0].username">
                                            <div v-if="message.participants.data.length > 1">
                                                <span v-if="user.img_url">
                                                    <img class="img-circle navbar-avatar"
                                                        v-bind:src="user.img_url + '?w=300&h=300&fit=crop'" />
                                                </span>
                                                <span v-else>
                                                    <img class="img-circle navbar-avatar"
                                                        src="../../../../public/img/user.svg" />
                                                </span>
                                                From: {{ message.participants.data[1].display_name }}
                                                <p>
                                                    <small v-if="message.messages.data.length" :class="[
                                                        messages[key].read === true
                                                            ? 'text-muted'
                                                            : 'font-weight-bold',
                                                    ]">
                                                        {{
                                                            fromUTC(message.messages.data[0].created_at, 'MMMM Do YYYY, h:mm a')
                                                        }}
                                                    </small>
                                                </p>
                                            </div>
                                        </span>
                                        <span v-else>
                                            <span v-if="message.participants.data[0].img_url">
                                                <img class="img-circle navbar-avatar" v-bind:src="message.participants.data[0].img_url + '?w=300&h=300&fit=crop'
                                                    " />
                                            </span>
                                            <span v-else>
                                                <img class="img-circle navbar-avatar"
                                                    src="../../../../public/img/user.svg" />
                                            </span>
                                            To: {{ message.participants.data[0].display_name }}
                                            <p>
                                                <small v-if="message.messages.data.length" :class="[
                                                    messages[key].read === true ? 'text-muted' : 'font-weight-bold',
                                                ]">
                                                    {{
                                                        fromUTC(message.messages.data[0].created_at, 'MMMM Do YYYY, h:mm a')
                                                    }}
                                                </small>
                                            </p>
                                        </span>
                                    </small>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <pagination class="mt-2" :pagination="pagination" :loading="initialLoading" @pageChange="updatePage">
                </pagination>
            </div>
        </div>
    </div>
</template>

<script>
import modal from '../../components/modal.vue';
import show from './show.vue';
import create from './create.vue';
import pagination from '../../components/pagination.vue';
import Query from '../../query/query.js';
import {
    fromUTC,
    truncate,
} from "../../filters/index.js";

export default {
    props: {
        convo: {
            type: String,
            required: false,
        },
    },
    created() {
        if (this.convo) {
            this.query.reset();
            this.conversation = this.convo;
            this.query.conversation = this.convo;
            this.query.parse(window.location.search);
        } else {
            this.query.parse(window.location.search);
        }
        if (window.screen.width < 768) {
            this.view = '';
        }

        this.fetchData();
    },
    data() {
        return {
            conversation: false,
            selectedAll: false,
            selectedConversation: [],
            convoSelected: false,
            toggleOptions: false,
            selected: [],
            deleteSuccess: false,
            showModal: false,
            view: 'create',
            errors: false,
            messages: [],
            initialLoading: true,
            selected: {},
            pagination: {
                current_page: 1,
            },
            query: new Query({
                page: '',
                filter: '',
                conversation: '',
            }),
        };
    },
    watch: {
        deleteSuccess: function () {
            if (this.deleteSuccess !== null) {
                window.setInterval(
                    function () {
                        this.deleteSuccess = false;
                    }.bind(this),
                    3000
                );
            }
        },
    },
    computed: {
        user() {
            return window.User;
        },
        unreadConvoCount() {
            return this.messages.reduce((acc, val) => {
                return acc + (val.read === false);
            }, 0);
        },
    },
    components: {
        pagination,
        create,
        modal,
        show,
    },
    methods: {
        fromUTC,
        truncate,
        profileUrl(msg) {
            if (msg.participants.data.length > 1) {
                const receiver = msg.participants.data[0].username;
                const sender = msg.participants.data[1].username;
                if (receiver == window.User.username) {
                    return '/users/' + sender;
                } else {
                    return '/users/' + receiver;
                }
            } else {
                return '/users/' + window.User.username;
            }
        },
        toggleReadStatus(msg, key) {
            axios.post('/api/conversations/toggle-read-status/' + msg.uuid).then(res => {
                this.selectedConversation.read = !msg.read;
                this.messages[key].read = this.selectedConversation.read;
            });
        },
        selectAll() {
            if (this.selected.length === this.messages.length) {
                this.selected = [];
                this.selectedAll = false;
            } else {
                const messages = this.messages;
                this.selected = messages;
                this.selectedAll = true;
            }
        },
        selectMsgs(val) {
            let message = this.selected.find(msg => msg.uuid === val.uuid);
            if (message) {
                this.selectedAll = false;
                this.selected = this.selected.filter(msg => msg.uuid !== val.uuid);
            } else {
                this.selected.push(val);
            }
        },
        clearSelected() {
            this.fetchData();
            this.selected = '';
            this.view = 'none';
        },
        setView(val) {
            this.view = val;
        },
        isActive(val) {
            if (this.filter === val) {
                return true;
            } else {
                return false;
            }
        },
        clearAggregate() {
            return (this.selected = []);
        },
        deleteMsgs() {
            var convos = this.selected.map(x => x.uuid);
            axios
                .delete('/api/conversations/', {
                    headers: { noConfirm: true },
                    params: { uuids: convos },
                })
                .then(
                    function (res) {
                        this.deleteSuccess = true;
                        this.clearAggregate();
                        this.fetchData();
                        this.view = 'create';
                        this.query.conversation = null;
                        this.handleQueryUpdate();
                        this.clearSelected();
                    }.bind(this)
                );
        },
        fetchData() {
            console.log('fetch data');
            axios.get('/api/conversations?' + this.query.toString()).then(
                response => {
                    this.pagination = response.data.meta.pagination;
                    var msgs = response.data.data;
                    this.selected = [];
                    this.messages = msgs;
                    if (this.conversation && this.conversation.length) {
                        return new Promise(resolve => {
                            var selected = msgs.find(x => x.uuid === this.conversation);
                            resolve(selected);
                        }).then(selected => {
                            var index = msgs.indexOf(selected);
                            this.convoSelected = true;
                            this.initialLoading = false;
                            this.selectMessage(selected, index);
                        });
                    }
                    this.initialLoading = false;
                },
                response => {
                    this.errors = response;
                }
            );
        },
        selectMessage(message, key) {
            this.selected = [];
            this.selectedConversation = message;
            this.query.conversation = message.uuid;
            this.query.page = this.query.page;
            this.query.push();
            if (message.read === false) {
                this.toggleReadStatus(message, key);
            }
            this.$nextTick(() => {
                this.view = 'show';
                this.selectedConversation = message;
            });
        },
        handleQueryUpdate() {
            this.fetchData();
            this.query.push();
        },
        updatePage(number) {
            this.conversation = null;
            this.query.reset();
            this.query.page = number;
            this.view = '';
            this.pagination.current_page = number;
            this.handleQueryUpdate();
        },
        isChecked(message) {
            if (this.selected.includes(message)) {
                return true;
            }
        },
    },
};
</script>
