<template>
    <span v-show="count" class="notification-badge badge badge-danger" v-html="count"></span>
</template>

<script>
export default {
    name: 'notification-count',
    computed: {
        count() {
            return window.User.unread_notification_count;
        },
    },
};
</script>

<style>
.notification-badge {
    font-family: sans-serif;
}
</style>
