<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              @click="(sortDesc = !sortDesc), updateQuery()"
            >
              <i :class="filterButton"></i>
            </button>

            <div class="btn-group d-none d-lg-inline pl-2">
              <button
                type="button"
                data-toggle="dropdown"
                class="btn btn-secondary dropdown-toggle"
              >
                {{ truncate(selectedFilter, 10, "...") }}
              </button>

              <div class="dropdown-menu">
                <a
                  @click.prevent="(selectedFilter = filter), updateQuery()"
                  class="dropdown-item"
                  v-for="filter in filters"
                  v-bind:key="filter"
                  href="#"
                >
                  {{ filter }}
                </a>
              </div>
            </div>

            <div class="btn-group d-none d-lg-inline pl-2">
              <button class="btn btn-primary"
                      type="button"
                      @click.prevent="toggleAllGenresFollow()">
                {{ this.followingAll ? 'Follow Selected' : 'Follow All' }}
              </button>
            </div>

            <input
              @keydown.enter.prevent="fetchData()"
              @keyup.delete="clearQuery()"
              @keydown.delete="clearQuery()"
              v-model="query.q"
              class="form-control col-xl-4 col-lg-2 ml-lg-auto"
              placeholder="Search for genres..."
            />
            <button class="btn btn-primary ml-2" @click.prevent="fetchData()">
              Search
            </button>
          </div>

          <div class="card-body">
            <span class="d-flex flex-row justify-content-center mb-3 d-md-none">
              <div class="btn-group">
                <button
                  type="button"
                  data-toggle="dropdown"
                  class="btn btn-secondary dropdown-toggle"
                >
                  {{ truncate(userFilter, 10, "...") }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  @click="(sortDesc = !sortDesc), updateQuery()"
                >
                  <i :class="filterButton"></i>
                </button>
                <div class="dropdown-menu">
                  <a
                    @click.prevent="(userFilter = filter), updateQuery()"
                    class="dropdown-item"
                    v-for="filter in userFilters"
                    v-bind:key="filter"
                    href="#"
                  >
                    {{ filter }}
                  </a>
                </div>
              </div>

              <div class="btn-group pl-2">
                <button
                  type="button"
                  data-toggle="dropdown"
                  class="btn btn-secondary dropdown-toggle"
                >
                  {{ truncate(selectedFilter, 10, "...") }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  @click="(sortDesc = !sortDesc), updateQuery()"
                >
                  <i :class="filterButton"></i>
                </button>
                <div class="dropdown-menu">
                  <a
                    @click.prevent="(selectedFilter = filter), updateQuery()"
                    class="dropdown-item"
                    v-for="filter in filters"
                    v-bind:key="filter"
                    href="#"
                  >
                    {{ filter }}
                  </a>
                </div>
              </div>
            </span>

            <ul class="list-group" v-if="options.length">
              <li class="list-group-item d-flex flex-row justify-content-start">
                <small class="ml-1"> Genre </small>
                <small class="ml-auto"> Date Followed </small>
              </li>
              <li
                class="list-group-item d-flex flex-row justify-content-start"
                v-for="(option, index) in options"
              >
                <span class="mr-auto">
                  <input
                    type="checkbox"
                    :checked="option.date_followed"
                    @click="toggleFollow($event, option)"
                  />
                  <span class="ml-2">
                    {{ truncate(option.genre, 20, "...") }}
                  </span>
                </span>

                <span v-if="option.date_followed">
                  <small class="ml-auto">
                    <span>
                      Since: {{ option.date_followed.data.created_at }}
                    </span>
                  </small>
                </span>
              </li>
            </ul>

            <div v-else-if="this.query.q && !this.options.length">
              <div class="mb-0 alert alert-info">No genres found.</div>
            </div>
            <div v-else-if="!this.options.length">
              <div class="mb-0 alert alert-info">Not following any genres.</div>
            </div>

            <pagination
              class="mt-2"
              v-if="options.length"
              :pagination="pagination"
              :loading="isLoading"
              @pageChange="updatePage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Query from "../../../query/query.js";
import pagination from "../../../components/pagination.vue";
import axios from 'axios';
import {
    truncate,
} from "../../../filters/index.js";

export default {
  components: {
    Query,
    pagination,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      allSelected: false,
      query: new Query({
        q: "",
        page: "",
        sort_by: "genre",
        follow_type: "genres",
        sort_order: "ascending",
        include: "date_followed",
        allgenres: true,
      }),
      aggregated: [],
      isLoading: false,
      options: [],
      filters: ["All Genres", "Following"],
      userFilters: ["Alphabetic", "Date Followed"],
      toggled: [],
      sortDesc: false,
      showControls: false,
      pagination: {
        current_page: 1,
      },
      userFilter: "Alphabetic",
      selectedFilter: "All Genres",
      followingAll: false,
    };
  },

  methods: {
    truncate,
    fetchData() {
    //   console.log(
    //     "/api/users/" +
    //       window.User.uuid +
    //       "/following-genres/?" +
    //       this.query.toString()
    //   );
      axios
        .get(
          "/api/users/" +
            window.User.uuid +
            "/following-genres/?" +
            this.query.toString()
        )
        .then((response) => {
          this.options = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.checkAllGenresFollow();
        });
    },

    sendMessage() {
      window.location.assign("/messages");
    },
    isToggled(key) {
      if (this.aggregated && this.toggled.includes(key)) {
        return true;
      }
      return false;
    },
    aggregateAll() {
      if (this.allSelected) {
        this.aggregated = [];
        this.allSelected = false;
      } else {
        this.options.forEach(function (element) {
          this.aggregated.push(element);
        }, this);
        this.allSelected = true;
      }
    },
    clearQuery() {
      if (this.query.q === "" || this.query.q === " ") {
        this.fetchData();
      }
    },
    updateQuery() {
      if (this.sortDesc === true) {
        this.query.sort_order = "descending";
      } else {
        this.query.sort_order = "ascending";
      }
      if (this.userFilter === "Alphabetic") {
        this.query.sort_by = "genre";
      } else if (this.userFilter === "Date Followed") {
        this.query.sort_by = "date_followed";
      }
      if (this.selectedFilter === "All Genres") {
        this.query.allgenres = true;
      } else if (this.selectedFilter === "Following") {
        this.query.allgenres = false;
      }
      this.fetchData();
    },
    aggregate(val) {
      if (this.aggregated.includes(val)) {
        const index = this.aggregated.indexOf(val);
        this.aggregated.splice(index, 1);
      } else {
        this.aggregated.push(val);
      }
    },
    handleQueryUpdate() {
      this.query.push();
      this.fetchData();
      if (this.query.save_search) {
        localStorage.iotmSearch = location.search;
      }
    },
    updatePage(number) {
      this.pagination.current_page = number;
      this.query.page = number;
      this.handleQueryUpdate();
    },

    toggleFollow($event, option) {
      if ($event.target.checked) {
        return this.follow(option.id);
      }
      return this.unfollow(option.id);
    },

    toggleAllGenresFollow() {
      if (this.followingAll) {
        this.unfollowAllGenres();
      } else {
        this.follow(3);
      }
    },

    checkAllGenresFollow() {
      axios
        .get(
          "/api/users/" +
          window.User.uuid +
          "/does-user-follow-all-genres"
        )
        .then((response) => {
          this.followingAll = response.data;
        });
    },

    follow(id) {
      axios.post("/api/genres/" + id + "/follow").then((response) => {
        this.fetchData();
      });
    },

    unfollow(id) {
      const url = `${"/api/genres/" + id}/unfollow`;
      axios.delete(url).then(() => {
        this.fetchData();
      });
    },

    unfollowAllGenres() {
      const url = "/api/genres/3/unfollow";
      console.log('unfollow called');
      swal({
          title: 'Follow only selected genres?',
          text: 'Confirm you only wish to follow the genres selected.',
          icon: 'warning',
          buttons: [true, 'Yes'],
      }).then((result) => {
        if (result) {
          axios.delete(url, { headers: { noConfirm: true } }).then(() => {
              this.fetchData();
          });
        }
      });

    },
  },
  watch: {},
  computed: {
    filterButton() {
      return {
        "fa fa-sort-alpha-desc":
          this.userFilter === "Alphabetic" && this.sortDesc === true,
        "fa fa-sort-alpha-asc":
          this.userFilter === "Alphabetic" && this.sortDesc !== true,
        "fa fa-sort-numeric-desc":
          this.userFilter === "Date Followed" && this.sortDesc === true,
        "fa fa-sort-numeric-asc":
          this.userFilter === "Date Followed" && this.sortDesc !== true,
      };
    },
  },
};
</script>
