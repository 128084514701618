<template>
    <modal v-show="modelValue" class="text-center">
        <template v-slot:body>
            <div v-if="guest">
                <h4>This is a deluxe feature.</h4>
                <p class="pb-3">Login to continue.</p>
                <a class="mr-2 btn btn-primary" :href="'/login?intended=' + currentUri">Login</a>
                <button class="btn btn-secondary" @click="close">Not Now</button>
            </div>

            <div v-else>
                <h4>This is a deluxe feature.</h4>
                <p class="pb-3">Upgrade to Deluxe to continue.</p>
                <a class="mr-2 btn btn-primary" href="/subscriptions">Upgrade</a>
                <a v-if="redirect" class="btn btn-secondary" :href="redirect"> Not Now </a>
                <button v-else class="btn btn-secondary" @click="close">Not Now</button>
            </div>
        </template>
    </modal>
</template>

<script setup>
import modal from './modal.vue';
import { computed } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
    },

    redirect: {
        type: String,
        required: false,
    },
});

const emit = defineEmits([
    'update:modelValue',
]);

const guest = computed(() => {
    return !window.User;
});

const currentUri = computed(() => {
    return encodeURIComponent(window.location.pathname);
});

const close = () => {
    emit('update:modelValue', false);
};
</script>

<script>
export default {
    compatConfig: {
        COMPONENT_V_MODEL: false
    }
};
</script>
