<template>
    <div>
        <nav class="navbar sub-nav navbar-bordered bg-white">
            <div class="container d-flex justify-content-center">
                <ul class="container p-0 nav nav-pills nav-centered">
                    <li class="nav-item">
                        <a
                            href="#national"
                            class="m-1 btn"
                            :class="filter == 'National' ? 'btn-primary' : 'btn-secondary'"
                            @click="setFilter('National')"
                        >
                            National New
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            href="#local"
                            class="m-1 btn"
                            :class="filter == 'Local New' ? 'btn-primary' : 'btn-secondary'"
                            @click="setFilter('Local New')"
                        >
                            Local New
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            href="#popular"
                            class="m-1 btn"
                            :class="filter == 'Local Popular' ? 'btn-primary' : 'btn-secondary'"
                            @click="setFilter('Local Popular')"
                        >
                            Local Popular
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="container my-3">
            <div class="row">
                <div class="col-lg-8 col-xl-9">
                    <availabilities class="mb-2" :filter="filter"></availabilities>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <venues :filter="filter"></venues>
                        </div>
                        <div class="col-md-6 mb-2">
                            <bands :filter="filter"></bands>
                        </div>
                    </div>
                    <photos dark limit="20" class="mb-2" :filter="filter"></photos>
                    <videos dark limit="20" :filter="filter"></videos>
                </div>
                <div class="col-lg-4 col-xl-3">
                    <news></news>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import availabilities from '../components/home/availabilities.vue';
import venues from '../components/home/venues.vue';
import bands from '../components/home/bands.vue';
import photos from '../components/photos/grid.vue';
import videos from '../components/videos/grid.vue';

export default {
    components: {
        availabilities,
        venues,
        bands,
        photos,
        videos,
    },
    data() {
        return {
            filter: 'National',
        };
    },

    created() {
        this.handleHash();
        window.onhashchange = this.handleHash;
    },

    methods: {
        handleHash() {
            if (window.location.hash === '#popular') {
                this.filter = 'Local Popular';
            } else if (window.location.hash === '#local') {
                this.filter = 'Local New';
            } else {
                this.filter = 'National';
            }
        },

        setFilter(filter) {
            this.filter = filter;
        },
    },
};
</script>
