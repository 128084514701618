<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9 mb-2">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Settings</h4>
                        <div>
                            <a class="btn btn-sm btn-outline-primary" href="/manage-follows"> Manage Follows </a>
                        </div>
                    </div>

                    <form v-if="form" @submit.prevent="onSubmit" @change="form.clearErrors($event.target.name)">
                        <div class="card-body px-4">
                            <div class="form-check">
                                <label class="form-check-label" for="private">
                                    <input
                                        type="checkbox"
                                        name="private"
                                        class="form-check-input"
                                        v-model="form.private"
                                    />
                                    Keep my profile private
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label" for="notify_email">
                                    <input
                                        type="checkbox"
                                        name="notify_email"
                                        class="form-check-input"
                                        v-model="form.notify_email"
                                    />
                                    Send me email notifications
                                </label>
                            </div>
                        </div>

                        <div>
                            <div class="card-header">
                                <strong>Notification Types</strong>
                            </div>
                            <div class="card-body px-4">
                                <div class="form-check">
                                    <label class="form-check-label" for="notify_message">
                                        <input
                                            type="checkbox"
                                            name="notify_message"
                                            class="form-check-input"
                                            v-model="form.notify_message"
                                        />
                                        New message
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_follower">
                                        <input
                                            type="checkbox"
                                            name="notify_follower"
                                            class="form-check-input"
                                            v-model="form.notify_follower"
                                        />
                                        New follower
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_show_availability">
                                        <input
                                            type="checkbox"
                                            name="notify_show_availability"
                                            class="form-check-input"
                                            v-model="form.notify_show_availability"
                                        />
                                        New show availability in markets I am following
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_venue_deleted">
                                        <input
                                            type="checkbox"
                                            name="notify_venue_deleted"
                                            class="form-check-input"
                                            v-model="form.notify_venue_deleted"
                                        />
                                        Venue I am following is deleted
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_venue_offline">
                                        <input
                                            type="checkbox"
                                            name="notify_venue_offline"
                                            class="form-check-input"
                                            v-model="form.notify_venue_offline"
                                        />
                                        Venue I am following goes offline
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_venue_booker">
                                        <input
                                            type="checkbox"
                                            name="notify_venue_booker"
                                            class="form-check-input"
                                            v-model="form.notify_venue_booker"
                                        />
                                        Booker information updated for a venue I follow
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_venue_media">
                                        <input
                                            type="checkbox"
                                            name="notify_venue_media"
                                            class="form-check-input"
                                            v-model="form.notify_venue_media"
                                        />
                                        Photos or videos added to a venue I follow
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_venue_review_request">
                                        <input
                                            type="checkbox"
                                            name="notify_venue_review_request"
                                            class="form-check-input"
                                            v-model="form.notify_venue_review_request"
                                        />
                                        A venue I played should be reviewed
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_user_media">
                                        <input
                                            type="checkbox"
                                            name="notify_user_media"
                                            class="form-check-input"
                                            v-model="form.notify_user_media"
                                        />
                                        Photos or videos added by a user I follow
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_user_calendar">
                                        <input
                                            type="checkbox"
                                            name="notify_user_calendar"
                                            class="form-check-input"
                                            v-model="form.notify_user_calendar"
                                        />
                                        A user I am following has updated their calendar
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label" for="notify_quickpitch_reminder">
                                        <input
                                            type="checkbox"
                                            name="notify_quickpitch_reminder"
                                            class="form-check-input"
                                            v-model="form.notify_quickpitch_reminder"
                                        />
                                        It is time to follow up with Quickpitch venues
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="card-header">
                            <strong>Change Password</strong>
                        </div>

                        <div class="card-body px-4">
                            <div class="form-group row mt-2" :class="{ 'has-danger': form.errors.get('password') }">
                                <div class="col-lg-6">
                                    <label for="password"><small class="text-center">Password</small></label>
                                    <input
                                        type="password"
                                        name="password_confirmation"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('password') }"
                                        v-model="form.password"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('password')"
                                        v-text="form.errors.get('password')"
                                    ></span>
                                </div>
                            </div>
                            <div
                                class="form-group row mt-2"
                                :class="{ 'has-danger': form.errors.get('password_confirmation') }"
                            >
                                <div class="col-lg-6">
                                    <label for="password_confirmation"
                                        ><small class="text-center">Password Confirmation</small></label
                                    >
                                    <input
                                        type="password"
                                        name="password_confirmation"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('password_confirmation') }"
                                        v-model="form.password_confirmation"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('password_confirmation')"
                                        v-text="form.errors.get('password_confirmation')"
                                    ></span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row justify-content-center form-group mb-3">
                            <btn-state :state="form.state" class="btn btn-primary"> Save Settings </btn-state>
                        </div>

                        <div class="card-header">
                            <strong>Account Management</strong>
                        </div>

                        <div class="card-body px-4 my-2">
                            <div class="btn-group">
                                <button @click.prevent="terminate" class="btn btn-danger">
                                    <i class="fa fa-trash"></i> Delete
                                </button>
                                <button @click.prevent="disable" class="btn btn-outline-danger">
                                    <i class="fa fa-ban"></i> Deactivate
                                </button>
                            </div>
                        </div>
                    </form>

                    <!-- Loading (remove this?) -->
                    <div v-else style="height: 100vh"></div>
                </div>
            </div>

            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../../form/form.js';
import btnState from '../../components/btnState.vue';
import news from '../../components/news.vue';

export default {
    name: 'edit-profile',
    props: {
        uuid: {
            required: true,
            type: String,
        },
    },
    components: {
        news,
        btnState,
    },

    data() {
        return {
            profileFetchFailed: false,
            form: false,
        };
    },

    computed: {
        deleteAccountUrl() {
            return '/api/users';
        },
        deactivateAccountUrl() {
            return '/api/users/deactivate';
        },
    },

    created() {
        this.fetchProfile(this.uuid);
    },
    methods: {
        disable() {
            var url = this.deactivateAccountUrl;
            return new Promise((resolve, reject) => {
                swal({
                    title: 'Are you sure?',
                    text: 'You will lose access to your account.',
                    icon: 'warning',
                    buttons: [true, 'Yes, deactivate it!'],
                    dangerMode: true,
                }).then(willDeactivate => {
                    if (willDeactivate) {
                        resolve(axios.post(url));
                    }
                });
            }).then(function (response) {
                window.location = '/';
            });
        },
        terminate() {
            axios.delete(this.deleteAccountUrl).then(function (response) {
                window.location = '/';
            });
        },
        fetchProfile(uuid) {
            var $v = this;
            axios
                .get('/api/users/' + uuid)
                .then(function (response) {
                    $v.form = new Form(Object.assign({ password: '', password_confirmation: '' }, response.data.data));
                })
                .catch(function (error) {
                    $v.profileFetchFailed = error.data;
                });
        },

        onSubmit() {
            var $v = this;
            this.form
                .put('/api/settings/' + this.uuid)
                .then(function (response) {
                    $v.response = response.data;
                })
                .catch(function (error) {
                    $v.response = error.data;
                });
        },
    },
};
</script>
