<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex flex-row justify-content-start">
                            <div class="dropdown mr-2">
                                <button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" type="button">
                                    {{ locationTypes[query.location_type] }}
                                </button>
                                <div class="dropdown-menu">
                                    <a
                                        class="dropdown-item btn"
                                        v-for="(v, k) in locationTypes"
                                        @click="(query.location_type = k), clearQuery()"
                                    >
                                        {{ v }}
                                    </a>
                                </div>
                            </div>
                            <div class="btn-group d-none d-lg-inline pl-2">
                                <button class="btn btn-secondary text-nowrap"
                                        type="button"
                                        @click="setFollowing">
                                    {{ query.following_only ? 'Show All' : 'Show Following' }}
                                </button>
                            </div>
                        </div>

                        <input
                            @keydown.enter.prevent="queryUpdate"
                            v-model="query.q"
                            class="form-control col-xl-4 col-lg-2 ml-lg-auto"
                            :placeholder="`Search ${query.location_type}...`"
                        />
                        <button class="btn btn-primary ml-2" @click.prevent="queryUpdate">Search</button>
                    </div>

                    <div class="card-body" v-if="!isLoading">
                        <span v-if="options.length">
                            <ul class="list-group mt-2" v-if="options.length">
                                <li class="list-group-item d-flex flex-row justify-content-start align-items-center">
                                    <small @click="toggleOrder('name')" class="ml-1 locationSort">
                                        Name
                                        <i :class="sortClass('name')"></i>
                                    </small>
                                    <small @click="toggleOrder('date_followed')" class="ml-auto locationSort">
                                        Date Followed
                                        <i :class="sortClass('date_followed')"></i>
                                    </small>
                                </li>
                                <li
                                    class="list-group-item d-flex flex-row justify-content-start"
                                    v-for="(option, index) in options"
                                >
                                    <input
                                        type="checkbox"
                                        :checked="option.date_followed"
                                        @click="toggleFollow($event, option)"
                                    />
                                    <div class="mr-auto col-8">
                                        <span class="ml-2">
                                            {{ truncate(option.name, 15, '...') }}
                                        </span>
                                        <small v-if="query.location_type === 'cities' && option.state" class="ml-2">
                                            {{ option.state.data.name }}
                                        </small>
                                    </div>

                                    <span v-if="option.date_followed">
                                        <small>{{ fromUTC(option.date_followed.data.created_at, 'l') }} </small>
                                    </span>
                                </li>
                            </ul>
                        </span>
                        <span v-if="!options.length">
                            <div v-if="query.location_type === 'states'">
                                <div class="mb-0 alert alert-info">Not following any states.</div>
                            </div>
                            <div v-else-if="query.location_type === 'cities'">
                                <div class="mb-0 alert alert-info">Not following any cities.</div>
                            </div>
                        </span>
                    </div>

                    <div v-else>
                        <loading v-show="isLoading"></loading>
                    </div>

                    <pagination
                        v-if="options.length"
                        :pagination="pagination"
                        :loading="isLoading"
                        @pageChange="updatePage"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from '../../../query/query.js';
import loading from '../../../components/loading.vue';
import pagination from '../../../components/pagination.vue';
import {
    fromUTC,
    truncate,
} from "../../../filters/index.js";

export default {
    components: {
        Query,
        loading,
        pagination,
    },
    data() {
        return {
            isLoading: true,
            query: new Query({
                q: '',
                limit: 20,
                sort_by: 'name',
                page: '',
                following_only: false,
                follow_type: 'locations',
                location_type: 'cities',
                sort_order: 'ascending',
                include: 'date_followed',
            }),
            options: [],
            sortOptions: {
                name: 'Alphabetic',
                date_followed: 'Date Followed',
            },
            locationTypes: {
                cities: 'Cities',
                states: 'States',
            },
            pagination: {
                current_page: 1,
            },
        };
    },

    created() {
        this.query.parse(location.search);
        this.fetchLocations();
    },

    methods: {
        fromUTC,
        truncate,
        setFollowing() {
            this.query.following_only = !this.query.following_only;
            if (this.query.following_only) {
                this.query.sort_by = 'date_followed';
            } else {
                this.query.sort_by = 'name';
            }
            this.queryUpdate();
        },

        fetchLocations() {
            axios.get(this.fetchUrl + '?' + this.query.toString()).then(res => {
                this.options = res.data.data;
                this.pagination = res.data.meta.pagination;
                this.isLoading = false;
            });
        },

        follow(id) {
            axios.post(this.locationUrl + id + '/follow').then(response => {
                this.fetchLocations();
            });
        },

        unfollow(id) {
            const url = `${this.locationUrl + id}/unfollow`;

            axios.delete(url).then(() => {
                this.fetchLocations();
            });
        },

        toggleOrder(sort_by) {
            if (!this.query.following_only && sort_by === 'date_followed') return;
            this.query.sort_by = sort_by;
            this.query.sort_order = this.query.sort_order === 'ascending' ? 'descending' : 'ascending';
            this.queryUpdate();
        },

        toggleFollow($event, option) {
            if ($event.target.checked) {
                return this.follow(option.id);
            }
            return this.unfollow(option.id);
        },

        clearQuery() {
            this.query.q = '';
            this.queryUpdate();
        },

        queryUpdate() {
            this.query.page = 1;
            this.query.push();
            this.fetchLocations();
        },

        updatePage(number) {
            this.pagination.current_page = number;
            this.query.page = number;
            this.query.push();
            this.fetchLocations();
        },

        sortClass(sort_by) {
            if (sort_by === this.query.sort_by) {
                return {
                    'fa fa-sort-amount-desc': this.query.sort_order === 'descending',
                    'fa fa-sort-amount-asc': this.query.sort_order === 'ascending',
                };
            }
        },
    },

    computed: {
        locationUrl() {
            return `/api/${this.query.location_type}/`;
        },

        fetchUrl() {
            return this.query.following_only ? this.followsUrl : this.locationUrl;
        },

        followsUrl() {
            return '/api/users/' + window.User.uuid + `/following-${this.query.location_type}`;
        },
    },
};
</script>

<style scoped>
.item + .item:before {
    content: ',';
}
</style>
