<template>
    <div>
        <button @click="handleClick" class="btn btn-sm btn-secondary m-1">
            <slot></slot>
        </button>

        <modal v-show="showModal" class="text-center">
            <template v-slot:body>
                <p class="pb-3">Login to continue</p>
                <a class="btn btn-primary" :href="redirectUrl">Login</a>
                <button class="btn btn-secondary" @click="showModal = false">Not Now</button>
            </template>
        </modal>

        <modal :width="600" v-show="showForm" @close="handleClose">
            <template v-slot:body>
                <form
                    @input="
                        conversation.clearErrors();
                        message.clearErrors();
                        clearValidated();
                    "
                    @click.stop
                    @submit.prevent="submit"
                >
                    <div class="form-group">
                        <label>Subject</label>
                        <input v-model="conversation.subject" class="w-100 form-control" type="text" />
                    </div>
                    <div class="form-group">
                        <label>Message</label>
                        <textarea v-model="message.body" class="w-100 form-control" type="text"></textarea>
                    </div>
                    <div class="d-flex">
                        <button @click.prevent="handleClose" class="btn btn-secondary">Cancel</button>
                        <btn-state :state="submitState" btnClass="ml-2 btn btn-primary"> Send </btn-state>
                    </div>
                </form>
            </template>
        </modal>
    </div>
</template>

<script>
import BtnState from './btnState.vue';
import Form from './../form/form.js';
import modal from './modal.vue';

export default {
    components: { modal, BtnState },

    props: {
        prefillLink: {
            default: true,
        },
        to: {
            type: String,
            required: true,
        },
        subject: {
            type: String,
            default: '',
        },
        from: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            validated: true,
            showForm: false,
            showModal: false,
            message: new Form({
                body: this.prefillLink ? window.location.href : '',
            }),
            conversation: new Form({
                subject: this.subject,
                participants: [this.to],
            }),
        };
    },

    computed: {
        redirectUrl() {
            return '/login?intended=' + window.location.pathname;
        },

        submitState() {
            if (!this.validated) return 'error';

            return this.message.state !== 'none' ? this.message.state : this.conversation.state;
        },
    },

    methods: {
        handleClick() {
            if (window.User) {
                this.showForm = true;
            } else {
                this.showModal = true;
            }
        },

        handleClose() {
            this.showForm = false;
            if (this.conversation === 'success') {
                this.conversation = new Form({
                    subject: '',
                    participants: [this.to],
                });
                this.conversation = new Form({
                    body: '',
                });
            }
        },

        clearValidated() {
            this.validated = true;
        },

        submit() {
            if (!this.message.body || !this.conversation.subject) {
                this.validated = false;
                return;
            }

            this.conversation.post('/api/conversations').then(res => {
                this.message.post(`/api/conversations/${res.data.data.uuid}/messages`).then(() => {
                    setTimeout(() => {
                        this.handleClose();
                    }, 1000);
                });
            });
        },
    },
};
</script>
