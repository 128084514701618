<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="container">
                    <div class="row justify-content-between">
                        <h4 class="m-0">IOTM Search</h4>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <!-- Search -->
                <div class="input-group">
                    <input
                        v-model="query.q"
                        @keydown="handleInput"
                        @keyup.enter="search"
                        class="form-control"
                        placeholder="Search"
                    />
                    <span class="input-group-btn">
                        <button @click="search" class="btn btn-secondary" type="button">
                            <i v-show="searching" class="fa fa-spinner fa-spin"></i>
                            <i v-show="!searching" class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <loading style="height: 10vh" v-show="loading"></loading>

        <div class="my-2" v-if="totals.venues > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.venues }} venues matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/venues?sort_by=relevance&q=' + query.q">View All</a>
            </div>
            <venue-results :query="query" :venues="results.venues"></venue-results>
        </div>

        <div class="my-2" v-if="totals.bands > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.bands }} users matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/users?sort_by=relevance&q=' + query.q">View All</a>
            </div>
            <band-results :query="query" :users="results.bands"></band-results>
        </div>

        <div class="my-2" v-if="totals.showAvailabilities > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.showAvailabilities }} show availabilities matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/show-availabilities?sort_by=relevance&q=' + query.q"
                    >View All</a
                >
            </div>
            <show-availability-results
                :query="query"
                :availabilities="results.showAvailabilities"
            ></show-availability-results>
        </div>

        <div class="my-2" v-if="totals.bandAvailabilities > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.bandAvailabilities }} band availabilities matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/band-availabilities?sort_by=relevance&q=' + query.q"
                    >View All</a
                >
            </div>
            <band-availability-results
                :query="query"
                :availabilities="results.bandAvailabilities"
            ></band-availability-results>
        </div>

        <div class="my-2" v-if="totals.classifieds > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.classifieds }} classifieds matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/classifieds?sort_by=relevance&q=' + query.q"
                    >View All</a
                >
            </div>
            <classified-results :query="query" :classifieds="results.classifieds"></classified-results>
        </div>

        <div class="my-2" v-if="totals.articles > 0">
            <div class="card card-body bg-dark text-white flex-row align-items-center d-flex justify-content-between">
                <h6 class="m-0">{{ totals.articles }} blog articles matching search</h6>
                <a class="ml-2 btn btn-sm btn-primary" :href="'/blog?sort_by=relevance&q=' + query.q"
                    >View All</a
                >
            </div>
            <article-results :query="query" :articles="results.articles"></article-results>
        </div>

        <div v-if="!loading && query.q.length < 2 && noResults" class="m-4 py-5 text-center alert alert-info">
            Enter at least 2 letters to search.
        </div>

        <div v-else-if="searched && noResults && !loading" class="m-4 py-5 text-center alert alert-info">
            No results found
        </div>
    </div>
</template>

<script>
/* global location */
import axios from 'axios';
import loading from '../../components/loading.vue';
import Query from '../../query/query.js';
import venueResults from '../../components/venues/results.vue';
import bandResults from '../../components/users/results.vue';
import showAvailabilityResults from '../../components/availabilities/venueResults.vue';
import bandAvailabilityResults from '../../components/availabilities/bandResults.vue';
import classifiedResults from '../../components/classifieds/results.vue';
import articleResults from '../../components/articles/results.vue';

export default {
    components: {
        loading,
        venueResults,
        bandResults,
        showAvailabilityResults,
        bandAvailabilityResults,
        classifiedResults,
        articleResults,
    },
    data() {
        return {
            loading: true,
            searching: false,
            searched: false,
            results: {
                venues: [],
                bands: [],
                showAvailabilities: [],
                bandAvailabilities: [],
                classifieds: [],
                articles: [],
            },
            totals: {
                venues: 0,
                bands: 0,
                showAvailabilities: 0,
                bandAvailabilities: 0,
                classifieds: 0,
                articles: 0,
            },
            query: new Query({
                q: '',
                limit: 3,
                sort_by: 'relevance',
            }),
        };
    },

    computed: {
        noResults() {
            return Object.keys(this.results).every(k => {
                return !this.results[k].length;
            });
        },
    },

    created: function () {
        if (location.search) {
            this.query.parse(location.search);
            this.search();
        }

        this.loading = false;
    },

    methods: {
        search() {
            if (this.query.q.length < 2) {
                this.searched = true;
                this.loading = false;
                return;
            }
            this.query.push();
            this.searching = true;
            axios
                .all([
                    this.venues(),
                    this.bands(),
                    this.showAvailabilities(),
                    this.bandAvailabilities(),
                    this.classifieds(),
                    this.articles(),
                ])
                .then(
                    axios.spread((venues, bands, showAvailabilities, bandAvailabilities, classifieds, articles) => {
                        this.handleResponse(venues, 'venues');
                        this.handleResponse(bands, 'bands');
                        this.handleResponse(showAvailabilities, 'showAvailabilities');
                        this.handleResponse(bandAvailabilities, 'bandAvailabilities');
                        this.handleResponse(classifieds, 'classifieds');
                        this.handleResponse(articles, 'articles');
                        this.loading = false;
                        this.searched = true;
                        this.searching = false;
                    })
                );
        },

        handleInput() {
            this.searched = false;
        },

        handleResponse(response, item) {
            this.totals[item] = response.data.meta.pagination.total;
            this.results[item] = response.data.data;
        },

        venues() {
            return axios.get('/api/venues?include=genres,blacklist_genres&' + this.query.toString());
        },

        bands() {
            return axios.get('/api/users?' + this.query.toString());
        },

        showAvailabilities() {
            return axios.get('/api/venue-availabilities?' + this.query.toString());
        },

        bandAvailabilities() {
            return axios.get('/api/band-availabilities?' + this.query.toString());
        },

        classifieds() {
            return axios.get('/api/classifieds?' + this.query.toString());
        },

        articles() {
            return axios.get('/api/articles?' + this.query.toString());
        },
    },
};
</script>

<style scoped>
.bg-dark text-white {
    background-color: #292b2c;
    border-color: whitesmoke;
}
</style>
