<template>
    <div class="star">
        <i @click="$emit('rate', value)" @mouseover="$emit('hover', value)" :class="classObj"></i>
    </div>
</template>

<script>
export default {
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            required: true,
        },
        amount: {
            type: Number,
            default: false,
        },
        hoveredRating: {
            type: Number,
            default: false,
        },
    },

    computed: {
        hovered() {
            return this.hoveredRating >= this.value;
        },

        classObj() {
            return {
                fa: true,
                'fa-star-o': this.amount < 0.25 && !this.hovered,
                'fa-star-half-o': this.amount >= 0.25 && this.amount < 0.75,
                'fa-star': this.amount >= 0.75 || this.hovered,
                'hovered-star': this.hovered,
            };
        },
    },

    methods: {
        toggleHover() {
            this.hovered = this.readOnly ? false : !this.hovered;
        },
    },
};
</script>

<style>
.hovered-star {
    color: goldenrod;
}
</style>
