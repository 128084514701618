<template>
    <div class="card">
        <div class="card-header bg-primary">
            <ul class="avail-tabs nav nav-tabs card-header-tabs h5 float-left">
                <li class="nav-item">
                    <a
                        class="cursor-pointer nav-link"
                        :class="tab == 'venue-availabilities' ? 'active text-primary' : 'text-white'"
                        @click="setTab('venue-availabilities')"
                    >
                        <span class="d-none d-sm-inline">Show Availabilities</span>
                        <span class="d-sm-none">Shows</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="cursor-pointer nav-link"
                        :class="tab == 'band-availabilities' ? 'active text-primary' : 'text-white'"
                        @click="setTab('band-availabilities')"
                    >
                        <span class="d-none d-sm-inline">Band Availabilities</span>
                        <span class="d-sm-none">Bands</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="cursor-pointer nav-link"
                        :class="tab == 'classifieds' ? 'active text-primary' : 'text-white'"
                        @click="setTab('classifieds')"
                    >
                        <span>Classifieds</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <component v-show="items.length" :noHeader="true" :is="tab" :items="items" :loading="loading"></component>

            <div class="alert alert-info mt-3" v-show="!loading && items.length === 0">
                No {{ filter }} {{ readable(tab) }}
            </div>

            <div class="d-flex justify-content-center">
                <a :href="href(tab)" class="btn btn-outline-primary"> All {{ readable(tab) }} </a>
            </div>
        </div>
    </div>
</template>

<script>
import venueAvailabilities from '../../components/home/venueResults.vue';
import bandAvailabilities from '../../components/home/bandResults.vue';
import classifieds from '../../components/home/classifiedResults.vue';

export default {
    props: ['filter'],

    components: { venueAvailabilities, bandAvailabilities, classifieds },

    data() {
        return {
            tab: 'venue-availabilities',
            tabReadable: {
                'venue-availabilities': 'Show Availabilities',
                'band-availabilities': 'Band Availabilities',
                classifieds: 'Classifieds',
            },
            items: [],
            loading: true,
        };
    },

    watch: {
        filter: function () {
            this.fetchItems();
        },
    },

    computed: {
        query() {
            const query = '?limit=3&sort_by=created_at&sort_order=descending';
            if (this.filter === 'National' || !window.User.postal_code) {
                return query;
            }

            return query + '&zip=' + window.User.postal_code + '&radius=100';
        },
    },

    created() {
        this.fetchItems();
    },

    methods: {
        setTab(tab) {
            this.tab = tab;
            this.fetchItems();
        },

        fetchItems() {
            axios.get('/api/' + this.tab + this.query).then(response => {
                this.pagination = response.data.meta.pagination;
                this.items = response.data.data;
                this.loading = false;
            });
        },

        readable(tab) {
            return this.tabReadable[tab];
        },

        href(tab) {
            return tab === 'venue-availabilities' ? 'show-availabilities' : tab;
        },
    },
};
</script>
