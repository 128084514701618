<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="container">
                    <div class="row justify-content-between">
                        <h4 class="m-0">
                            Venues <span class="small muted" v-show="venueCount">({{ venueCount }})</span>
                        </h4>
                        <a class="btn btn-sm btn-secondary align-self-center" href="/contact/add-venue">
                            <i class="fa fa-plus pr-1"></i>
                            Add A Venue
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <filters
                    :showDistance="showDistance"
                    :query="query"
                    :showingFilters="showingFilters"
                    :isPremium="isPremium"
                    :isLogged="isLogged"
                    @toggleOrder="toggleOrder"
                    @hideFilters="toggleFilters"
                    @queryUpdate="handleQueryUpdate"
                    ref="filters"
                />

                <div class="d-flex justify-content-between">
                    <div>
                        <a class="btn btn-primary text-white align-self-start mr-1" @click="toggleFilters"
                            v-show="!showingFilters">
                            <i class="fa fa-plus"> </i> Filters
                        </a>

                        <premium-modal v-model="showPremiumModal"></premium-modal>

                        <a @click="toggleMap" class="btn btn-secondary">
                            <i class="fa fa-map-marker"></i>
                            <span v-show="showingMap">Hide</span> Map
                        </a>
                    </div>

                    <div>
                        <a @click="resetFilters()" class="btn btn-secondary"> Reset </a>
                        <button @click="handleQueryUpdate" class="btn btn-primary text-white ml-1">Search</button>
                    </div>
                </div>
            </div>
        </div>

        <venue-map class="my-1" @closeMap="showingMap = false" :visible="showingMap" :items="venues"></venue-map>

        <quickpitch class="my-1" :quickpitch="quickpitch" :venueCount="pagination.total"
            @quickpitchUpdate="quickpitchUpdate"></quickpitch>

        <results class="my-1" :isPremium="isPremium" @sortBy="sortBy" :query="query" :showDistance="showDistance"
            :venues="venues" :loading="initialLoading" :quickpitch="quickpitch" @quickpitchUpdate="quickpitchUpdate">
        </results>

        <quickpitch
            class="my-1"
            :quickpitch="quickpitch"
            :venueCount="pagination.total"
            @quickpitchUpdate="quickpitchUpdate"
        />
        <pagination class="mt-3" :pagination="pagination" :loading="initialLoading" @pageChange="updatePage"></pagination>
    </div>
</template>

<script>
/* global location */
import { debounce } from '../../utils';
import pagination from '../../components/pagination.vue';
import venueMap from '../../components/maps/map.vue';
import results from '../../components/venues/results.vue';
import filters from '../../components/venues/filters.vue';
import quickpitch from '../../components/venues/quickpitch.vue';
import Query from '../../query/query.js';
import premiumModal from '../../components/premiumModal.vue';

export default {
    components: { premiumModal, pagination, venueMap, quickpitch, results, filters },
    data() {
        return {
            showingFilters: true,
            showingMap: false,
            initialLoading: true,
            mapLoading: false,
            venues: [],
            pagination: {
                current_page: 1,
                total_pages: 1,
            },
            showPremiumModal: false,
            quickpitch: { venues: { data: [] } },
            query: new Query({
                q: '',
                sort_by: 'popularity',
                sort_order: 'descending',
                country: '',
                state: '',
                city: '',
                country_name: '',
                state_name: '',
                city_name: '',
                zip: '',
                radius: '',
                genre: '',
                // premium only
                categories: [],
                genres: [],
                song_types: '',
                min_capacity: '',
                max_capacity: '',
                days: [],
                // min_age: '',
                page: '',
                save_search: false,
                only_show_followed: false,
            }),
        };
    },

    created: function () {
        window.onpopstate = event => {
            this.query.parse(event.target.location.search);
            this.fetchVenues();
        };

        if (this.isDeluxe) {
            this.fetchQuickpitch();
        }

        // this.query.country = 1 // USA default

        if (location.search) {
            this.query.parse(location.search);
            this.handleQueryUpdate(true, true);
        } else if (this.isPremium && localStorage.iotmSearch) {
            this.query.parse(localStorage.iotmSearch);
            this.handleQueryUpdate(false, true);
        } else {
            this.handleQueryUpdate();
        }
    },
    mounted() {
        function exists(query) {
            if (Array.isArray(query)) {
                return query && query.length;
            } else {
                return query && query !== '';
            }
        }

        var premiumFilters = [
            this.query.min_capacity,
            this.query.max_capacity,
            this.query.days,
            this.query.song_types,
            this.query.categories,
        ];

        let showPremium = premiumFilters.filter(exists);

        if (this.query.save_search === 'true' || showPremium.length !== 0) {
            this.showingFilters = true;
        }
    },

    computed: {
        updateQpUrl() {
            return '/api/quickpitch/' + this.quickpitch.uuid + '?include=venues,good_venues,bad_venues,recent_venues,blocked_venues';
        },

        fetchQpUrl() {
            return (
                '/api/quickpitch/' +
                window.User.draft_quickpitch_uuid +
                '?include=venues,good_venues,bad_venues,blocked_venues,recent_venues'
            );
        },

        isDeluxe() {
            return window.User.is_deluxe;
        },

        isPremium() {
            return window.User.is_premium;
        },

        isLogged() {
            return window.User !== false;
        },

        fetchUrl() {
            return '/api/venues?include=categories,genres,blacklist_genres&' + this.query.toString();
        },

        showDistance() {
            return Boolean(this.venues[0] && this.venues[0].distance);
        },

        venueCount() {
            return this.pagination.total;
        },
    },
    methods: {
        resetPagination() {
            this.pagination.current_page = 1;
            this.pagination.total_pages = 1;
        },

        resetFilters() {
            this.query.reset();
            this.query.save_search = true;
            this.handleQueryUpdate();
            this.$refs.filters.clearErrors();
        },

        fetchVenues() {
            if (this.query.sort_by === 'distance' && !(this.query.zip || this.query.city)) {
                this.query.sort_by = 'popularity';
            }

            this.initialLoading = true;

            axios
                .get(this.fetchUrl)
                .then(response => {
                    this.pagination = response.data.meta.pagination;
                    this.venues = response.data.data;
                    this.initialLoading = false;
                })
                .catch(error => {
                    this.initialLoading = false;
                    this.venues = [];
                    this.resetPagination();
                });
        },

        handleQueryUpdate(pageChange = false, replace = false) {
            if (!pageChange) {
                this.query.page = '';
                this.pagination.current_page = 1;
            }

            if (replace) {
                this.query.replace();
            } else {
                this.query.push();
            }
            if (this.query.save_search) {
                localStorage.iotmSearch = location.search;
            }

            this.fetchVenues();
        },

        fetchQuickpitch() {
            if (window.User.draft_quickpitch_uuid) {
                axios.get(this.fetchQpUrl).then(response => {
                    this.quickpitch = response.data.data;
                    this.quickpitch.pitch = this.quickpitch.pitch ? this.quickpitch.pitch.data.uuid : null;
                });
            } else {
                axios.post('/api/quickpitch?include=venues,good_venues,bad_venues,blocked_venues,recent_venues').then(response => {
                    this.quickpitch = response.data.data;
                });
            }
        },

        quickpitchUpdate(venues, reset = false) {
            var data = Object.assign({}, this.quickpitch, { venues });
            if (reset && !data.pitch_id) {
                data.body = null;
                data.subject = null;
                data.pitch = null;
            }
            axios.put(this.updateQpUrl, data).then(response => {
                this.quickpitch = response.data.data;
                this.quickpitch.pitch = this.quickpitch.pitch ? this.quickpitch.pitch.data.uuid : null;
            });
        },

        toggleMap() {
            this.showingMap = !this.showingMap;
        },

        toggleFilters() {
            if (!this.isPremium) {
                this.showPremiumModal = true;
            } else {
                this.showingFilters = !this.showingFilters;
            }
        },

        updatePage(number) {
            this.query.page = number;
            this.pagination.current_page = number;
            this.handleQueryUpdate(true);
        },

        sortBy(field) {
            this.query.sort_by = field;
            this.toggleOrder();
        },

        setOrder(order) {
            this.query.sort_order = order;
            this.handleQueryUpdate();
        },

        toggleOrder() {
            if (this.query.sort_order === 'ascending') {
                this.setOrder('descending');
            } else {
                this.setOrder('ascending');
            }
        },
    },
};
</script>
