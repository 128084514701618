<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="container">
                    <div class="row justify-content-between">
                        <h4 class="mb-0">
                            Show Availabilities
                            <span class="small muted" v-show="pagination.total">({{ pagination.total }})</span>
                        </h4>
                        <a v-if="user" href="/show-availabilities/manage"
                            class="ml-auto btn-sm btn btn-secondary align-self-end">
                            <i class="fa fa-cog pr-1"></i>
                            Manage
                        </a>
                        <a href="/show-availabilities/create"
                            class="ml-1 d-none d-md-inline btn-sm btn btn-secondary align-self-end">
                            <i class="fa fa-plus pr-1"></i>
                            Add
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <filters availtype="venue" :showDistance="showDistance" :query="query" @queryUpdate="handleQueryUpdate"
                    @toggleOrder="toggleOrder"></filters>

                <div class="d-flex justify-content-between">
                    <div>
                        <a @click="showingMap = !showingMap" class="btn btn-secondary">
                            <i class="fa fa-map-marker"></i>
                            <span v-show="showingMap">Hide</span> Map
                        </a>
                    </div>

                    <div>
                        <a @click="resetFilters" class="btn btn-secondary"> Reset </a>
                        <button @click="fetchAvailabilities" class="btn btn-primary text-white ml-1">Search</button>
                    </div>
                </div>
            </div>
        </div>

        <availability-map class="my-1" @closeMap="showingMap = !showingMap" :items="availabilities"
            :visible="showingMap"></availability-map>

        <results class="mt-1 mb-3" :availabilities="availabilities" :loading="initialLoading" :query="query"
            :showDistance="showDistance" @sortBy="sortBy"></results>

        <pagination :pagination="pagination" :loading="initialLoading" @pageChange="updatePage"></pagination>
    </div>
</template>

<script>
/* global location */
import pagination from '../../components/pagination.vue';
import availabilityMap from '../../components/maps/map.vue';
import results from '../../components/availabilities/venueResults.vue';
import filters from '../../components/availabilities/filters.vue';
import Query from '../../query/query.js';

export default {
    components: { pagination, availabilityMap, results, filters },

    data() {
        return {
            showingMap: false,
            initialLoading: true,
            mapLoading: false,
            availabilities: [],
            pagination: {
                current_page: 1,
            },
            query: new Query({
                q: '',
                sort_by: 'created_at',
                sort_order: 'descending',
                genre: 3,
                country: '',
                state: '',
                city: '',
                zip: '',
                radius: '',
                page: '',
            }),
        };
    },

    created: function () {
        window.onpopstate = event => {
            this.query.parse(event.target.location.search);
            this.fetchAvailabilities();
        };

        // this.query.country = 1 // USA default

        if (location.search) {
            this.query.parse(location.search);
        }

        this.fetchAvailabilities();
    },

    computed: {
        user() {
            return window.User;
        },
        fetchUrl() {
            return '/api/venue-availabilities?' + this.query.toString();
        },
        showDistance() {
            return Boolean(this.availabilities[0] && this.availabilities[0].distance);
        },
    },

    methods: {
        resetPagination() {
            this.pagination.current_page = 1;
            this.pagination.total_pages = 1;
        },

        resetFilters() {
            this.query.reset();
            this.handleQueryUpdate();
        },

        fetchAvailabilities() {
            this.initialLoading = true;
            axios
                .get(this.fetchUrl)
                .then(response => {
                    this.pagination = response.data.meta.pagination;
                    this.availabilities = response.data.data;
                    this.initialLoading = false;
                })
                .catch(error => {
                    this.initialLoading = false;
                    this.availabilities = [];
                    this.resetPagination();
                });
        },

        handleQueryUpdate(pageChange = false) {
            if (!pageChange) {
                this.query.page = '';
                this.pagination.current_page = 1;
            }
            this.query.push();
            this.fetchAvailabilities();
        },

        updatePage(number) {
            this.query.page = number;
            this.pagination.current_page = number;
            this.handleQueryUpdate(true);
        },

        sortBy(field) {
            this.query.sort_by = field;
            this.toggleOrder();
        },

        setOrder(order) {
            this.query.sort_order = order;
            this.handleQueryUpdate();
        },

        toggleOrder() {
            if (this.query.sort_order === 'ascending') {
                this.setOrder('descending');
            } else {
                this.setOrder('ascending');
            }
        },
    },
};
</script>
