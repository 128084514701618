<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9 mb-2">
                <div class="card">
                    <div class="card-header text-center" v-if="isCreate">Create New Classified Listing</div>
                    <div class="card-header text-center" v-else>Edit {{ form.title }}</div>

                    <div v-if="form">
                        <div class="card-body">
                            <div @keydown="form.clearErrors($event.target.name)">
                                <div class="form-group" :class="{ 'has-danger': form.errors.get('title') }">
                                    <label for="title">Title *</label>
                                    <input
                                        v-model="form.title"
                                        type="text"
                                        name="title"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('title') }"
                                    />
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('title')"
                                        v-text="form.errors.get('title')"
                                    ></span>
                                </div>

                                <div class="form-group" :class="{ 'has-danger': form.errors.get('description') }">
                                    <label for="desc">Description *</label>
                                    <textarea
                                        v-model="form.description"
                                        type="text"
                                        name="description"
                                        class="form-control"
                                        :class="{ 'form-control-danger': form.errors.get('description') }"
                                    >
                                    </textarea>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('description')"
                                        v-text="form.errors.get('description')"
                                    ></span>
                                </div>

                                <!-- Datepicker -->

                                <div class="form-group" :class="{ 'has-danger': form.errors.get('date_expires') }">
                                    <label for="date_expires">Expires On *</label>
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <div
                                                class="el-form-item mb-0"
                                                :class="{ 'is-error': form.errors.has('date_expires') }"
                                            >
                                                <flat-pickr
                                                    class="w-100 form-control"
                                                    :config="config"
                                                    @input="form.clearErrors()"
                                                    v-on:change="form.clearErrors('change')"
                                                    placeholder="Select Date"
                                                    v-model="form.date_expires"
                                                    name="date_expires"
                                                >
                                                </flat-pickr>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <select
                                                class="form-control my-2"
                                                v-model="start"
                                                @change="form.clearErrors()"
                                            >
                                                <option v-for="s in startOptions">
                                                    {{ s }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('date_expires')"
                                        v-text="form.errors.get('date_expires')"
                                    >
                                    </span>
                                </div>

                                <b class="d-block pb-2"> Location </b>

                                <div class="form-group" :class="{ 'has-danger': form.errors.get('country') }">
                                    <address-fields
                                        :form="form"
                                        @change="form.clearErrors()"
                                        :requiredFields="['country', 'state', 'city', 'postal_code']"
                                        :hiddenFields="['address']"
                                    >
                                    </address-fields>
                                    <span
                                        class="form-control-feedback"
                                        v-show="form.errors.has('location')"
                                        v-text="form.errors.get('location')"
                                    ></span>
                                </div>

                                <!-- Photos -->

                                <label for="name">Photos</label>

                                <div class="card-body px-4">
                                    <photo-upload-container
                                        :uuid="uuid"
                                        type="classified"
                                        :url="`/api/classifieds/${uuid}/photos`"
                                    />
                                </div>

                                <div class="d-flex flex-row justify-content-center form-group">
                                    <btn-state v-on:click="onSubmit" :state="form.state" class="btn btn-secondary">
                                        Next
                                        <a class="p-2">
                                            <i class="fa fa-arrow-right"></i>
                                        </a>
                                    </btn-state>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import btnState from '../../components/btnState.vue';
import addressFields from '../../components/addressFields.vue';
import Form from '../../form/form.js';
import news from '../../components/news.vue';
import moment from 'moment';
import PhotoUploadContainer from '../../components/PhotoUploadContainer.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    props: {
        editingUuid: {
            required: false,
            type: String,
        },
    },

    components: {
        PhotoUploadContainer,
        btnState,
        addressFields,
        news,
        flatPickr,
    },

    data() {
        return {
            isCreate: false,
            start: moment().startOf('day').add(20, 'hours').format('h:mm a'),
            uuid: '',
            form: new Form({
                title: '',
                date_expires: moment().add(1, 'days').toDate(),
                description: '',
                city_id: '',
                custom_city: '',
                state_id: '',
                country_id: 1,
                postal_code: '',
            }),
            config: {
                dateFormat: 'Y-m-d',
            },
        };
    },

    created() {
        if (!this.editingUuid) {
            this.isCreate = true;
            axios
                .post('/api/classifieds/', {
                    title: 'title',
                })
                .then(response => {
                    this.uuid = response.data.data.uuid;
                });
        } else {
            this.uuid = this.editingUuid;
            axios.get('/api/classifieds/' + this.uuid).then(response => {
                this.form = new Form(Object.assign(this.form, response.data.data));
                this.start = moment(this.form.date_expires).format('h:mm a');
                this.uuid = this.form.uuid;
            });
        }
    },

    computed: {
        startOptions() {
            let starts = [];
            const start = moment().startOf('day').add(8, 'hours');
            const end = moment().endOf('day');
            const interval = 15;
            while (start.add(interval, 'minutes') < end) {
                starts.push(start.format('h:mm a'));
            }
            return starts;
        },
    },

    methods: {
        submitForm(uuid) {
            this.form
                .put('/api/classifieds/' + uuid)
                .then(response => {
                    this.response = response.data;
                    window.location = '/classifieds/' + response.data.data.slug + '/' + response.data.data.uuid;
                })
                .catch(error => {
                    this.response = error.data;
                });
        },

        onSubmit() {
            let x = moment(this.form.date_expires).format('YYYY-MM-DD');
            let time = moment(x + ' ' + this.start, 'YYYY-MM-DD h:mm a');
            let date = time.format('YYYY-MM-DD HH:mm:ss');
            this.form.date_expires = date;
            this.submitForm(this.uuid);
        },
    },
};
</script>

<style scoped>
#thumbNail {
    max-width: 260px;
    max-height: 260px;
}
.fileInput {
    width: 50rem;
}
</style>
