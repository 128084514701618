<template>
    <div class="card">
        <h1 class="h4 mb-0 card-header card-title">Blocked Users</h1>

        <div class="card-body">
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>

            <div v-if="showNoResults" class="alert alert-info">You have not blocked any users.</div>

            <table v-if="showTable" class="table">
                <thead>
                    <tr>
                        <th class="border-top-0">User</th>
                        <th class="border-top-0 text-right">Unblock</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="b in blocks" key="b.uuid">
                        <td>
                            <a :href="'/users/' + b.username">
                                {{ b.display_name }}
                            </a>
                        </td>
                        <td class="text-right">
                            <button class="btn btn-secondary btn-sm" @click="unblock(b)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: ['uuid'],

    data() {
        return {
            blocks: [],
            loading: true,
            error: '',
        };
    },

    created() {
        this.fetchBlocks();
    },

    computed: {
        showNoResults() {
            return !this.loading && !this.showTable;
        },
        showTable() {
            return this.blocks && this.blocks.length;
        },
    },

    methods: {
        fetchBlocks() {
            axios
                .get('/api/users/' + this.uuid + '/blocked-users')
                .then(r => {
                    this.loading = false;
                    this.blocks = r.data.data;
                })
                .catch(e => {
                    this.setError('There was a problem retrieving blocked users.');
                });
        },

        unblock(b) {
            axios
                .delete('api/users/' + b.uuid + '/unblock')
                .then(r => {
                    this.blocks = this.blocks.filter(b => b.uuid !== r.data.data.uuid);
                })
                .catch(e => {
                    this.setError('There was a problem unblocking ' + b.username + '.');
                });
        },

        setError(message) {
            this.error = message;
            setTimeout(() => {
                this.error = '';
            }, 4000);
        },
    },
};
</script>
