<template>
    <div>
        <table v-if="!loading" class="table">
            <thead class="bg-primary">
                <tr class="small text-white">
                    <th>
                        Article
                    </th>
                    <th lass="d-none d-sm-table-cell">
                        Date
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="article in articles">
                    <td class="row">
                        <div class="col">
                            <h6>
                                <a class="break-word" :href="'/blog/' + article.date.year + '/' + article.date.month + '/' + article.slug">
                                    {{ truncate(article.title, 100, '...') }}
                                </a>
                            </h6>
                            <span v-if="article.tags.data.length" class="d-flex flex-row flex-wrap pb-1">
                                <span
                                    class="badge badge-primary mr-1 mb-1"
                                    v-for="tag in article.tags.data"
                                >
                                    {{ tag.name }}
                                </span>
                            </span>
                            <div>
                                {{ article.content_short}}
                            </div>
                            <a :href="'/blog/' + article.date.year + '/' + article.date.month + '/' + article.slug" class="mt-1 pull-right btn btn-sm btn-secondary">
                                View Full Article
                            </a>
                        </div>

                    </td>
                    <td class="date-cell">{{ dateFormat(article.date, 'MM-DD-YY') }}</td>
                </tr>
            </tbody>
        </table>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';
import {
    dateFormat,
    truncate,
} from "../../filters/index.js";

export default {
    props: ['loading', 'items', 'articles'],

    name: 'article-results',

    components: { loading },

    methods: {
        dateFormat,
        truncate
    }
};
</script>
