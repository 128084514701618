import { createApp } from 'vue';

/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Create the app instance
const app = createApp({});

/**
 * Directives
 */

import { hide } from "./directives/index.js";
app.directive("hide", hide);

/*
 * Pages
 */

// Band-Availabilities
import BandAvailabilitiesEdit from "./pages/bandAvailabilities/edit.vue";
import BandAvailabilitiesIndex from "./pages/bandAvailabilities/index.vue";
import BandAvailabilitiesManage from "./pages/bandAvailabilities/manage.vue";
app.component("band-availabilities-edit", BandAvailabilitiesEdit);
app.component("band-availabilities-index", BandAvailabilitiesIndex);
app.component("band-availabilities-manage", BandAvailabilitiesManage);

// Reviews
import Reviews from "./components/home/reviews.vue";
app.component("reviews", Reviews);

// Search
import SearchAll from "./pages/search/index.vue";
app.component("search-all", SearchAll);

// Classifieds
import ClassifiedsEdit from "./pages/classifieds/edit.vue";
import ClassifiedsIndex from "./pages/classifieds/index.vue";
import ClassifiedsManage from "./pages/classifieds/manage.vue";
app.component("classifieds-edit", ClassifiedsEdit);
app.component("classifieds-index", ClassifiedsIndex);
app.component("classifieds-manage", ClassifiedsManage);

// Testimonials
import TestimonialCreate from "./pages/testimonials/create.vue";
app.component("testimonial-create", TestimonialCreate);

// Media
import PhotosEdit from "./pages/photos/edit.vue";
import PhotosManage from "./components/photos/manage.vue";
import VideosCreate from "./pages/videos/create.vue";
import VideosEdit from "./pages/videos/edit.vue";
import VideosManage from "./components/videos/manage.vue";
app.component("photos-edit", PhotosEdit);
app.component("photos-manage", PhotosManage);
app.component("videos-create", VideosCreate);
app.component("videos-edit", VideosEdit);
app.component("videos-manage", VideosManage);

// Quickpitch
import QuickpitchEdit from "./pages/quickpitch/edit.vue";
import QuickpitchPreview from "./pages/quickpitch/preview.vue";
app.component("quickpitch-edit", QuickpitchEdit);
app.component("quickpitch-preview", QuickpitchPreview);

// Newsletter
import NewsletterSubscribe from "./pages/newsletter/subscribe.vue";
app.component("newsletter-subscribe", NewsletterSubscribe);

// Contact
import AddVenue from "./pages/contact/addVenue.vue";
import Contact from "./pages/contact/contact.vue";
app.component("add-venue", AddVenue);
app.component("contact", Contact);

// Dashboard
import Dashboard from "./pages/dashboard.vue";
app.component("dashboard", Dashboard);

// Inbox
import Inbox from "./pages/inbox/index.vue";
app.component("inbox", Inbox);

// Venue-Availabilities
import VenueAvailabilitiesEdit from "./pages/venueAvailabilities/edit.vue";
import VenueAvailabilitiesIndex from "./pages/venueAvailabilities/index.vue";
import VenueAvailabilitiesManage from "./pages/venueAvailabilities/manage.vue";
app.component("venue-availabilities-edit", VenueAvailabilitiesEdit);
app.component("venue-availabilities-index", VenueAvailabilitiesIndex);
app.component("venue-availabilities-manage", VenueAvailabilitiesManage);

// Venues
import VenuesIndex from "./pages/venues/index.vue";
app.component("venues-index", VenuesIndex);

// Users
import BlockedUsers from "./pages/users/blocked-users.vue";
import EditSettings from "./pages/users/edit-settings.vue";
import Follows from "./pages/users/follows/index.vue";
import ProfilesEdit from "./pages/profiles/edit.vue";
import SubscriptionsEdit from "./pages/users/editSubscriptions.vue";
import UsersIndex from "./pages/users/index.vue";
app.component("blocked-users", BlockedUsers);
app.component("edit-settings", EditSettings);
app.component("follows", Follows);
app.component("profiles-edit", ProfilesEdit);
app.component("subscriptions-edit", SubscriptionsEdit);
app.component("users-index", UsersIndex);

// Notifications
import Notification from "./pages/notifications/show.vue";
import Notifications from "./pages/notifications/index.vue";
app.component("notification", Notification);
app.component("notifications", Notifications);

// Inquiries
import BookingInquiries from "./pages/inquiries/bookingInquiries.vue";
app.component("booking-inquiries", BookingInquiries);

/*
 * Components (only if blade files need them directly)
 */

// Contact
import ContactUser from "./components/contactUser.vue";
import ContactBooker from "./components/venues/contactBooker.vue";
app.component("contactUser", ContactUser);
app.component("contactBooker", ContactBooker);

// Map
import ItemMap from "./components/maps/singleItemMap.vue";
import MapToggle from "./components/maps/mapToggle.vue";
app.component("itemMap", ItemMap);
app.component("mapToggle", MapToggle);

// Media
import Photos from "./components/photos/grid.vue";
import PhotoCarousel from "./components/photos/carousel.vue";
import Videos from "./components/videos/grid.vue";
app.component("photos", Photos);
app.component("photo-carousel", PhotoCarousel);
app.component("videos", Videos);

// News
import News from "./components/news.vue";
app.component("news", News);

// Notifications
import NotificationCount from "./components/nav/notificationCount.vue";
import UnreadCount from "./components/nav/unreadCount.vue";
app.component("notificationCount", NotificationCount);
app.component("unreadCount", UnreadCount);

// Quickpitch
import AddToQuickpitch from "./components/quickpitch/addToQuickpitch.vue";
app.component("addToQuickpitch", AddToQuickpitch);

// Ratings
import StarRating from "./components/rating/rating.vue";
app.component("starRating", StarRating);

// Search
import MasterSearch from "./components/masterSearch.vue";
import MessageCount from "./components/nav/messageCount.vue";
app.component("masterSearch", MasterSearch);
app.component("messageCount", MessageCount);

/*
 * Event-calendar component
 */
import Calendar from "./components/calendar.vue";
app.component("calendar", Calendar);

/*
 * Dynamic Subject Field
 */
import DynamicSubjectField from "./components/dynamicSubjectField.vue";
app.component("dynamic-subject-field", DynamicSubjectField);

/*
 * Trix
 */
import VueTrix from "./components/trix-wysiwyg.vue";
app.component("trix", VueTrix);

/*
 * Select Components
 */
import AddressFields from "./components/addressFields.vue";
import DaySelect from "./components/daySelect.vue";
import GenreSelect from "./components/genreSelect.vue";
import StateSelect from "./components/stateSelect.vue";
import UserSelect from "./components/userSelect.vue";
import VenueSelect from "./components/venueSelect.vue";
app.component("address-fields", AddressFields);
app.component("day-select", DaySelect);
app.component("genre-select", GenreSelect);
app.component("state-select", StateSelect);
app.component("user-select", UserSelect);
app.component("venue-select", VenueSelect);

/*
 * Flat Pickr
 */
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

Vue.use(flatPickr);

app.config.compilerOptions.isCustomElement = tag => ['trix-editor', 'trix-toolbar'].includes(tag);
app.config.compilerOptions.whitespace = 'preserve';

app.mount("#app");
