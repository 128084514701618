<template>
    <div>
        <table v-if="!loading" class="table">
            <tbody class="bg-white">
                <tr v-for="(classified, index) in items">
                    <td :class="index === 0 ? 'pt-0 border-0 row' : 'row'">
                        <div class="col-auto">
                            <img v-if="classified.img_url" class="img-fluid"
                                :src="classified.img_url + '?w=100&h=100&fit=crop'" />
                            <img v-else class="user-default-sm o50 img-fluid" src="../../../../public/img/user.svg" />
                        </div>
                        <div class="col">
                            <h6>
                                <a class="break-word" :href="'/classifieds/' + classified.slug + '/' + classified.uuid">
                                    {{ truncate(classified.title, 100, '...') }}
                                </a>
                            </h6>
                            <p>
                                {{ cityState(classified) }}
                            </p>
                        </div>
                    </td>
                    <td class="date-cell">{{ dateFormat(classified.date_expires, 'MM-DD-YY') }}</td>
                </tr>
            </tbody>
        </table>
        <loading style="height: 10vh" v-show="loading"></loading>
    </div>
</template>

<script>
import loading from '../loading.vue';
import {
    dateFormat,
    truncate,
    cityState,
} from "../../filters/index.js";

export default {
    props: ['loading', 'items'],

    name: 'classified-results',

    components: { loading },

    methods: {
        dateFormat,
        truncate,
        cityState,
    }
};
</script>
