<template>
    <form @submit.prevent="$emit('queryUpdate')" @change="handleInput" class="mb-2">
        <!-- Show Search Only Default -->
        <div class="form-group">
            <input
                @input="query.sort_by = 'relevance'"
                v-model="query.q"
                class="form-control"
                placeholder="Search title, city, state"
            />
        </div>

        <!-- Location -->
        <address-filters @input="$emit('queryUpdate')" countries_with="classifieds" :query="query"></address-filters>

        <!-- Sort -->
        <div class="row">
            <div class="form-group col-sm-6 d-flex">
                <a @click.prevent="$emit('toggleOrder')" class="d-flex align-items-center mr-1 btn btn-secondary">
                    <i v-if="query.sort_order === 'ascending'" class="fa fa-sort-amount-asc"></i>
                    <i v-else class="fa fa-sort-amount-desc"></i>
                    Sort
                </a>
                <select v-model="query.sort_by" class="form-control">
                    <option value="date_expires">Expires At</option>
                    <option value="created_at">Date Added</option>
                    <option v-if="showDistance" value="distance">Distance</option>
                    <option v-if="query.q" value="relevance">Relevance</option>
                </select>
            </div>
        </div>
    </form>
</template>

<script>
/* global bus location */
import addressFilters from '../addressFilters.vue';

export default {
    name: 'classifieds-filter',

    components: { addressFilters },

    props: {
        query: {
            type: Object,
            required: true,
        },

        showDistance: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        handleInput() {
            const vm = this;
            // instead of this.$nextTick
            setTimeout(() => {
                vm.$emit('queryUpdate');
            });
        },
    },
};
</script>
