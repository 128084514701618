<template>
    <div class="categorySelect">
        <input type="hidden" :name="name" v-model="categoriesString" />

        <div class="mb-0" :class="{ 'is-error': error }">
            <v-select
                multiple
                v-model="localValue"
                :placeholder="placeholder"
                :append-to-body="true"
                clearable
                filterable
                no-match-text="No results"
                ref="input"
                @update:modelValue="handleChange($event)"
                :options="options"
                label="name"
            />
        </div>
    </div>
</template>

<script setup>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps({
    isFilter: {
        default: false,
    },

    error: {
        type: String,
    },

    name: {
        type: String,
        default: 'categories',
    },

    modelValue: {
        type: Array,
        required: false,
    },
});

const emit = defineEmits([
    'category-input',
]);

const options = ref([]);
const localValue = ref([]);

onMounted(() => {
    fetchCategories();
});

const placeholder = computed(() => {
    return props.isFilter ? 'All Categories' : 'Select Categories';
});

const categoriesString = computed(() => {
    return props.modelValue.reduce((acc, g) => {
        return !acc ? g.toString() : acc + ',' + g;
    }, '');
});

const handleChange = (val) => {
    const categories = val.map((category) => {
        return category.id;
    });

    emit('category-input', categories);
};

const fetchCategories = () => {
    return axios.get('/api/venue-categories')
        .then(response => {
            options.value = response.data.data;

            localValue.value = options.value.filter((category) => {
                return props.modelValue.includes(category.id);
            });
        });
};

watch(() => props.modelValue, (newValue) => {
    localValue.value = options.value.filter((category) => {
        return newValue.includes(category.id);
    });
}, { deep: true });
</script>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
};
</script>
