<template>
    <div class="genreSelect">
        <div class="mb-0">
            <v-select
                v-model="localValue"
                :options="options"
                :multiple="true"
                :placeholder="placeholder"
                @update:modelValue="handleChange($event)"
                track-by="id"
                :append-to-body="true"
                label="genre"
            />
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, watch } from 'vue';
    import axios from 'axios';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    const props = defineProps({
        isFilter: {
            default: false,
        },

        name: {
        type: String,
        default: 'genres',
        },

        modelValue: {
        type: Array,
        default: () => [],
        },
    });

    const emit = defineEmits([
        'update:modelValue',
    ]);

    const options = ref([]);

    const localValue = ref([]);

    const placeholder = computed(() => {
      return props.isFilter ? 'All Genres' : 'Select Genres';
    });

    const handleChange = (val) => {
        const genres = val.map((genre) => {
            return genre.id;
        });

        emit('update:modelValue', genres);
    }

    const fetchGenres = () => {
      return axios
        .get('/api/genres' + (props.isFilter ? '' : '?allgenres=true'))
        .then((response) => {
          options.value = response.data.data;

          localValue.value = options.value.filter((genre) => {
            return props.modelValue.includes(genre.id);
          });
        });
    };

    onMounted(() => {
        fetchGenres();
    });

    watch(() => props.modelValue, (newValue) => {
        localValue.value = options.value.filter((genre) => {
            return newValue.includes(genre.id);
        });
    }, { deep: true });
</script>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
};
</script>
