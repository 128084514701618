<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-lg-8 col-xl-9 mb-2">
                <div class="card px-sm-5 py-5 card-body">
                    <h4>IOTM Newsletter</h4>

                    <form @submit.prevent="submit" @keydown="form.clearErrors()">
                        <div :class="{ 'has-danger': form.errors.get('email') }" class="form-group">
                            <label for="email" class="control-label">E-Mail</label>

                            <input
                                v-model="form.email"
                                id="email"
                                class="form-control"
                                name="email"
                                required
                                autofocus
                            />

                            <span v-if="form.errors.has('email')" class="text-danger help-block">
                                <strong>{{ form.errors.get('email') }}</strong>
                            </span>
                        </div>

                        <btn-state btnClass="ml-auto btn-primary" :state="form.state"> Subscribe </btn-state>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3">
                <news></news>
            </div>
        </div>
    </div>
</template>

<script>
import news from '../../components/news.vue';
import btnState from '../../components/btnState.vue';
import Form from '../../form/form.js';

export default {
    components: { btnState, news },

    data() {
        return {
            form: new Form({
                email: window.User.email || '',
            }),
        };
    },
    methods: {
        submit() {
            this.form.post('/newsletter');
        },
    },
};
</script>
