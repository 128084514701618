<template>
    <div id="singleItemMap">
        <div
            style="height: 30vh"
            v-if="showMap"
        >
            <div style="height: 100%" id="map"></div>
        </div>
    </div>
</template>

<script>
/* global google */
import $script from 'scriptjs';

export default {
    props: ['lat', 'lng', 'title'],

    name: 'item-map',

    data() {
        return {
            showMap: false,
        };
    },

    created() {
        this.toggleMap();
    },

    mounted() {
        const parentNode = document.getElementById('singleItemMap')
            .parentNode;

        const parentObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type == 'attributes' && mutation.attributeName == 'style') {
                    this.showMap = !this.showMap;

                    Vue.nextTick(() => {
                        this.toggleMap();
                    })
                }
            }
        });

        const observerConfig = {
            attributes: true,
            attributeFilter: ['style'],
        };

        parentObserver.observe(parentNode, observerConfig);
    },

    methods: {
        toggleMap() {
            if (this.showMap) {
                const gmaps = 'https://maps.googleapis.com/maps/api/js?key=' + window.google_maps_js_key;

                $script(gmaps, this.drawMap);
            }
        },

        drawMap() {
            // Create a map object and specify the DOM element for display.
            const latitude = parseFloat(this.lat);
            const longitude = parseFloat(this.lng);
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: latitude, lng: longitude },
                scrollwheel: false,
                zoom: 8,
            });

            new google.maps.Marker({
                map: map,
                position: { lat: parseFloat(this.lat), lng: parseFloat(this.lng) },
            });
        },
    },
};
</script>
