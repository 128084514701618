<template>
    <div v-html="comments"></div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['limit'],

    created() {
        axios.get('/api/venue-reviews' + (this.limit ? '?limit=' + this.limit : '')).then(res => {
            this.comments = res.data;
        });
    },

    data() {
        return {
            comments: '',
        };
    },
};
</script>

<style>
ul.dsq-widget-list {
    padding-left: 0;
}
</style>
