<template>
    <div class="mb-2">
        <div class="container-fluid" id="createMessage">
            <div class="card">
                <div class="card-header d-flex flex-row justify-content-between messageHeader">
                    <div class="mx-auto">Create New Message</div>
                    <button type="button" class="close" @click="this.closeForm">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body p-3">
                    <form @submit.prevent="onSubmit" @keydown="form.clearErrors($event.target.name)">
                        <transition name="fade">
                            <div class="alert alert-danger" role="alert" v-if="form.errors.has('subject')">
                                <button type="button" class="close" data-dismiss="alert">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <strong>Error!</strong> {{ form.errors.get('subject') }}
                            </div>
                        </transition>

                        <transition name="fade">
                            <div class="alert alert-danger" role="alert" v-if="form.errors.has('body')">
                                <button type="button" class="close" data-dismiss="alert">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <strong>Error!</strong> {{ form.errors.get('body') }}
                            </div>
                        </transition>
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-warning" role="alert" v-show="invalid">
                                    Select A Different Recipient
                                </div>
                                <user-select @update="updateUsers" id="userSelect"></user-select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mt-2">
                                    <input v-model="form.subject" class="form-control" placeholder="Subject" />
                                </div>
                                <div class="form-group">
                                    <textarea
                                        v-model="form.body"
                                        class="form-control"
                                        placeholder="Body"
                                        rows="5"
                                        id="messageTextarea"
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 mx-auto text-center">
                                <btn-state
                                    v-bind:disabled="
                                        this.invalid === true ||
                                        (this.form.subject && this.form.body && this.form.participants === '')
                                    "
                                    :state="form.state"
                                    class="mx-auto btn btn-primary"
                                >
                                    Submit
                                </btn-state>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import btnState from '../../components/btnState.vue';
import Form from '../../form/form.js';

export default {
    components: { btnState },
    props: ['selected'],
    data() {
        return {
            form: new Form({
                subject: '',
                body: '',
            }),
            selectedUser: '',
            isLoading: false,
            messageSuccess: false,
        };
    },
    emits: [
        'sent',
        'close',
    ],
    created() {
        $('#userSelect').focus();
    },
    computed: {
        invalid() {
            if (this.selectedUser) {
                return this.selectedUser === window.User.uuid;
            }
        },
    },
    watch: {
        messageSuccess: function () {
            if (this.messageSuccess === true) {
                window.setInterval(
                    function () {
                        this.messageSuccess = false;
                    }.bind(this),
                    1500
                );
            }
        },
    },
    methods: {
        closeForm() {
            this.$emit('close');
        },
        updateUsers(query) {
            this.selectedUser = '';

            if (query) {
                this.selectedUser = query.uuid;
            }
        },
        onSubmit() {
            axios
                .post('/api/conversations', {
                    subject: this.form.subject,
                    participants: [this.selectedUser],
                })
                .then(response => {
                    this.form
                        .post('/api/conversations/' + response.data.data.uuid + '/messages', {
                            body: this.form.body,
                        })
                        .then(response => {
                            console.log('emitting sent');
                            this.$emit('sent');
                            this.messageSuccess = true;
                        });
                    this.form.reset();
                });
        },
    },
};
</script>
