<template>
    <modal v-show="modelValue" width="600">
        <template v-slot:body>
            <h4>QuickPitch Tips</h4>

            <p>
                Be sure to include a link to your music in your booking pitch as IOTM does NOT automatically attach a
                link back to your IOTM account. When using this feature, the best practice is to send emails out
                according to desired show date(s), so you can include that info in the subject and body of your message.
            </p>

            <ul class="my-2">
                <li>
                    <a href="/quickpitch-emailing" target="_blank">Read More About QuickPitch Emailing</a>
                </li>

                <li>
                    <a
                        href="/touring-tips-show-booking-advice-for-musicians/whats-my-sales-pitch-for-booking"
                        target="_blank"
                        >What's My Sales Pitch for Booking?</a
                    >
                </li>

                <li>
                    <a href="/indie-on-the-move-consulting-services" target="_blank"
                        >Need help composing the perfect booking sales pitch for your act?</a
                    >
                </li>
            </ul>

            <button class="mt-2 btn btn-secondary" @click="$emit('update:modelValue', false)">Close</button>
        </template>
    </modal>
</template>

<script>
import modal from '../modal.vue';

export default {
    compatConfig: {
        COMPONENT_V_MODEL: false
    },
    props: {
        modelValue: {
            required: true,
        },
    },

    components: { modal },
};
</script>
