<template>
    <div class="card">
        <div class="card-header">
            <a class="btn btn-link" href="/notifications"> <i class="fa fa-arrow-circle-left"></i> Back </a>
            <h5 class="mt-1 ml-auto">
                {{ value.data.subject }}
            </h5>
        </div>
        <div class="pt-2 mb-5 text-center card-body">
            <h6 class="m-3 card-title">
                <div v-html="value.data.message"></div>
                <a :href="value.data.action_url" v-if="value.data.action_url !== null">
                    {{ value.data.action_label || 'Click here to view' }}
                </a>
            </h6>
            <button class="m-2 dropdown-toggle btn btn-outline-secondary" data-toggle="dropdown">Mark As</button>
            <div class="dropdown-menu">
                <a href="#" class="dropdown-item" @click="markRead">Read</a>
                <a href="#" class="dropdown-item" @click="markUnread">Unread</a>
            </div>
            <div class="d-flex justify-content-around">
                <span>
                    {{ fromNow(value.created_at) }}
                </span>
                <span> Status: {{ value.read_at ? 'Read' : 'Unread' }} </span>
            </div>
        </div>
    </div>
</template>

<script>
import {
    fromNow,
} from "../../filters/index.js";

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    created() {
        const n = JSON.parse(this.value.data);
        this.value.data = n;
    },
    methods: {
        fromNow,
        deleteItem() {
            var ids = this.value.id;
            return new Promise((resolve, reject) => {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to undo this!',
                    icon: 'warning',
                    buttons: [true, 'Yes, delete it!'],
                    dangerMode: true,
                }).then(willDelete => {
                    if (willDelete) {
                        resolve(
                            axios
                                .put('/api/notifications/delete', {
                                    ids: ids,
                                })
                                .then(response => {
                                    window.location.href = '/notifications';
                                })
                        );
                    }
                });
            });
        },
        markUnread() {
            var ids = this.value.id;
            axios
                .put('/api/notifications/mark-unread', {
                    ids: [ids],
                })
                .then(response => {
                    window.location.reload();
                });
        },
        markRead() {
            var ids = this.value.id;
            axios
                .put('/api/notifications/mark-read', {
                    ids: [ids],
                })
                .then(response => {
                    window.location.reload();
                });
        },
    },
};
</script>
