<template>
    <nav v-if="!loading" class="d-flex justify-content-center" aria-label="pagination">
        <div v-show="offset > 0" class="page-item">
            <a @click="setPage(1)" class="text-primary page-link"> 1 </a>
        </div>
        <div v-show="offset > 0" class="p-2">...</div>
        <ul class="pagination">
            <li
                v-for="n in numVisiblePages"
                class="page-item"
                :class="{ active: isActive(n + offset), 'text-primary': !isActive(n + offset) }"
            >
                <a @click="setPage(n + offset)" class="page-link">
                    {{ n + offset }}
                </a>
            </li>
        </ul>
        <div v-show="range + offset < pagination.total_pages" class="p-2">...</div>
        <div v-show="range + offset < pagination.total_pages" class="page-item">
            <a @click="setPage(pagination.total_pages)" class="text-primary page-link">
                {{ pagination.total_pages }}
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            default: {
                count: 0,
                current_page: 1,
                per_page: 10,
                total: 0,
                total_pages: 1,
            },
        },
        range: {
            default: 5,
            type: Number,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },

    created() {},

    computed: {
        offset() {
            var offset = this.pagination.current_page - Math.ceil(this.range / 2);
            var maxOffset = this.pagination.total_pages - this.range;
            if (offset < 0 || maxOffset < 0) {
                return 0;
            } else if (offset > maxOffset) {
                return maxOffset;
            }
            return offset;
        },
        numVisiblePages() {
            return this.range > this.pagination.total_pages ? this.pagination.total_pages : this.range;
        },
    },

    methods: {
        setPage(number) {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.$emit('pageChange', number);
        },
        isActive(n) {
            return n === this.pagination.current_page;
        },
    },
};
</script>
