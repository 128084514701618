<template>
<div>
    <photo-manage
        :uuid="uuid"
        :type="type"
        ref="photoManageRef"
    />
    <photo-upload
        v-if="uuid"
        :url="url"
        @photo-uploaded="handleUpload"
    />
</div>
</template>

<script setup>
import photoManage from './photoManage.vue';
import photoUpload from './photoUpload.vue';
import { ref } from 'vue';

const props = defineProps({
    uuid: {
        type: String,
        required: false,
        default: '',
    },
    type: {
        type: String,
    },
    url: {
        type: String,
    }
});

const photoManageRef = ref(null)

const handleUpload = () => {
    photoManageRef.value.fetchPhotos();
}
</script>
