<template>
  <div>
    <!-- input for use in non ajax html form -->
    <input type="hidden" name="venue_uuid" :value="modelValue" />

    <!-- .is-error.el-form-item adds a red border -->
    <div class="form-group" :class="{ 'is-error': error }">
      <v-select
        :filterable="false"
        label="title"
        class="venue-select"
        v-model="selectedVenue"
        placeholder="Type to search venues.."
        @search="onSearch"
        @click="onSearch()"
        :options="venues"
        :append-to-body="true"
      >
        <template v-slot:option="option">
          <span>
            {{ option.title }}
            <span class="d-block">
              {{ cityState(option) }}
            </span>
          </span>
          <span :class="option.icon">
            <img
              class="img-thumbnail"
              :src="option.img_url + '?w=60&h=60&fit=crop'"
            />
          </span>
        </template>

        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No results found for <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Start typing to search for a venue.</em
          >
        </template>
      </v-select>
    </div>
  </div>
</template>

<script setup>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { cityState } from "../filters/index.js";
import { onMounted, computed, ref, watch } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  size: {
    type: String,
    default: "",
  },

  error: {
    type: String,
  },

  // venue.uuid
  modelValue: {
    type: String,
    required: true,
    default: () => "",
  },
});

const query = ref("");
const selectedVenue = ref("");
const venues = ref([]);
const cancelToken = ref(axios.CancelToken);
const currentRequest = ref(null);

const handleChange = (venue) => {
  emit("update:modelValue", venue ? venue.uuid : "");
};

const onSearch = (search) => {
  fetchVenues(search);
};

const loadVenue = (uuid) => {
  axios.get("/api/venues/" + uuid).then((res) => {
    selectedVenue.value = res.data.data;
  });
};

const fetchVenues = (search = "") => {
  if (currentRequest.value) {
    currentRequest.value.cancel();
  }

  currentRequest.value = cancelToken.value.source();

  let url = `/api/venues?sort_by=relevance&limit=${Config.multiselect_search_result_count}&q=${search}`;

  return axios
    .get(url, {
      cancelToken: currentRequest.value.token,
    })
    .then((response) => {
      venues.value = response.data.data;
      currentRequest.value = null;
    })
    .catch((err) => {});
};

watch(selectedVenue, (newValue) => {
  handleChange(newValue);
});

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (!oldValue) {
      loadVenue(newValue);
    }
  },
);

onMounted(() => {
  if (props.modelValue && !selectedVenue.value) {
    loadVenue(props.modelValue);
  }
});
</script>

<style>
.dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.dropdown li:last-child {
  border-bottom: none;
}
.dropdown li a {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.dropdown li a .fa {
  padding-right: 0.5em;
}
</style>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
};
</script>
