<template>
    <transition name="fade">
        <div class="card" v-if="calendarEvents[0] !== undefined">
            <div class="card-header bg-dark text-light d-flex justify-content-between">
                <h4 class="mb-0"><span class="d-none d-sm-inline">Upcoming </span>Events</h4>
                <img height="30" style="filter: invert(1)" :src="bandsintownLogo" />
            </div>

            <div class="calendar-card-body" style="background-color: rgb(85,172,238)">
                <div v-for="event in calendarEvents" class="event-item bg-white px-4 py-2 my-2 mx-16" style="border-radius:5px">
                    <!-- In here do whatever you want, make you owner event template -->
                    <small class="d-flex justify-content-between">
                        <span>{{ dateFormat(event.date, 'l') }}</span>
                        <a v-if="event.url" target="_blank" rel="noopener noreferrer" :href="event.url"
                            >View on Bands In Town</a
                        >
                    </small>
                    <p>
                        {{ event.title }}
                    </p>
                    <div v-if="param === 'venues' && event.users.data.length">
                        <b>Lineup: </b>
                        <ul>
                            <li v-for="user in event.users.data">
                                <a :href="'/users/' + user.username">{{ user.display_name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div v-else-if="param === 'users' && event.venue">
                        <b>Venue: </b>
                        <a :href="'/venues/' + event.venue.data.slug">{{ event.venue.data.title }}</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from 'moment';
import {
    dateFormat,
} from "../filters/index.js";

export default {
    props: {
        title: {
            required: true,
            type: String,
        },
        param: {
            required: true,
            type: String,
        },
        uuid: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            viewAll: false,
            visible: true,
            calendarEvents: [],
        };
    },

    created() {
        this.fetchCalendar();
    },

    methods: {
        dateFormat,
        resetEvents() {
            this.$EventCalendar.toDate('all');
        },
        handleDayChange(data) {
            if (data.date !== 'all') {
                this.viewAll = true;
            } else {
                this.viewAll = false;
            }
        },
        fetchCalendar() {
            axios.get(this.calendarUrl).then(response => {
                this.calendarEvents = response.data.data.map(e => {
                    return Object.assign({}, e, {
                        date: moment(e.date).format('YYYY/MM/DD'),
                    });
                });
            });
        },

        eventContent(e) {
            if (this.param === 'venues' && e.user) {
                return '<a href="/users/' + e.user.data.username + '">' + e.user.data.display_name + '</a>';
            }
            if (this.param === 'users' && e.venue) {
                return '<a href="/venues/' + e.venue.data.username + '">' + e.venue.data.title + '</a>';
            }

            return e.title;
        },
    },

    computed: {
        bandsintownLogo() {
            return window.location.origin + '/img/bandsintown.svg';
        },

        calendarUrl() {
            return '/api/calendar/' + this.param + '/' + this.uuid;
        },
    },
};
</script>

<style>
.__vev_calendar-wrapper .events-wrapper div.cal-events {
    height: auto;
}
.cal-events {
    overflow-y: scroll;
}
.events-wrapper {
    overflow-y: auto;
}

.calendar-card-body {
    max-height: 500px;
    overflow: scroll;
}

@media screen and (min-width: 768px) {
    .__vev_calendar-wrapper .cal-wrapper {
        width: 50%;
        padding: 0px 20px;
    }

    .event-item {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
