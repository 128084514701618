<template>
    <div>
        <v-select
            :filterable="false"
            label="username"
            class="user-select"
            v-model="selectedUser"
            @search="onSearch"
            :options="users"
            @update:modelValue="handleUserUpdate"
            placeholder="Type to search users.."
        >
            <template v-slot:option" slot-scope="option">
                <span>
                    {{ option.username }}
                    <span class="d-block">
                        {{ cityState(option) }}
                    </span>
                </span>
                <span :class="option.icon" v-if="option.img_url">
                    <img class="img-thumbnail" :src="option.img_url + '?w=60&h=60&fit=crop'" />
                </span>
            </template>
        </v-select>
    </div>
</template>

<script setup>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { cityState } from "../filters/index.js";
import { ref } from 'vue';

const loading = ref(false);
const selectedUser = ref('');
const users = ref([]);
const cancelToken = ref(axios.CancelToken);
const currentRequest = ref(null);

const emit = defineEmits([
    'update',
]);

const onSearch = (search, loading) => {
    fetchUsers(loading, search);
};

const handleUserUpdate = () => {
    emit('update', selectedUser.value);
};

const fetchUsers = (loading, query = '') => {
    if (currentRequest.value) {
        currentRequest.value.cancel();
    }

    if (query && query.length > 1) {
        loading(true);

        currentRequest.value = cancelToken.value.source();

        let url =
            '/api/users?sort_by=relevance&limit=' + Config.multiselect_search_result_count + '&q=' + query;

        axios
            .get(url, {
                cancelToken: currentRequest.value.token,
            })
            .then(response => {
                users.value = response.data.data;
                loading(false);
                currentRequest.value = null;
            })
            .catch(() => {
                // don't err after cancel
            });
    }
};
</script>

<style>
.dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.dropdown li:last-child {
    border-bottom: none;
}
.dropdown li a {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.dropdown li a .fa {
    padding-right: 0.5em;
}
</style>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
};
</script>
