<template>
    <div class="card">
        <div class="card-header">
            <h4 class="m-0">Create Testimonial</h4>
        </div>
        <form class="py-4 card-body" @submit.prevent="submit" @keydown="form.clearErrors($event.target.name)">
            <div class="form-group" :class="{ 'has-danger': form.errors.get('body') }">
                <textarea
                    :class="{ 'form-control-danger': form.errors.get('body') }"
                    placeholder="Your testimonial here..."
                    name="body"
                    v-model="form.body"
                    class="form-control"
                ></textarea>
                <span
                    class="form-control-feedback"
                    v-show="form.errors.has('body')"
                    v-text="form.errors.get('body')"
                ></span>
            </div>

            <div class="form-group d-flex align-items-center">
                <btn-state class="btn btn-primary" :state="form.state"> Create </btn-state>
                <a href="/testimonials" class="pl-2 btn-link">cancel</a>
            </div>

            <div v-show="form.hasSuccess()" class="text-center alert alert-success">
                <p class="mb-3">Thanks for your feedback!</p>
                <a class="btn btn-secondary btn-sm" href="/testimonials"> Go Back </a>
            </div>
        </form>
    </div>
</template>

<script>
import Form from '../../form/form.js';
import btnState from '../../components/btnState.vue';

export default {
    components: { btnState },

    data() {
        return {
            form: new Form({
                body: '',
            }),
        };
    },

    methods: {
        submit() {
            this.form.post('/api/testimonials');
        },
    },
};
</script>

<style scoped>
textarea {
    min-height: 40vh;
}
</style>
