<template>
    <div>
        <div class="form-group row" :class="{ 'has-danger': form.errors.get('country_id') }">
            <div class="col-12">
                <label for="country_id"
                    ><p>Country{{ isRequired('country') }}</p></label
                >
                <select
                    name="country_id"
                    class="form-control"
                    :class="{ 'form-control-danger': form.errors.get('country_id') }"
                    @change="
                        emitChange();
                        handleCountryChange();
                    "
                    v-model="form.country_id"
                >
                    <option value="" disabled>Select A Country</option>
                    <option v-for="country in countries" :value="country.id" v-text="country.name"></option>
                </select>
                <span
                    class="form-control-feedback"
                    v-if="form.errors.has('country_id')"
                    v-text="form.errors.get('country_id')"
                ></span>
            </div>
        </div>

        <div class="form-group row" :class="{ 'has-danger': form.errors.get('state_id') }">
            <div class="col-12">
                <label for="state_id"
                    ><p>State{{ isRequired('state') }}</p></label
                >
                <select
                    name="state_id"
                    class="form-control"
                    :class="{ 'form-control-danger': form.errors.get('state_id') }"
                    @change="
                        emitChange();
                        handleStateChange();
                    "
                    v-model="form.state_id"
                >
                    <option value="" disabled>Select A State</option>
                    <option v-for="state in states" :value="state.id" v-text="state.name"></option>
                </select>
                <span
                    class="form-control-feedback"
                    v-if="form.errors.has('state_id')"
                    v-text="form.errors.get('state_id')"
                ></span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-danger': form.errors.get('city_id') }">
            <label for="city_id"
                ><p>City{{ isRequired('city') }}</p></label
            >
            <div class="row">
                <div class="col-10">
                    <select
                        @change="
                            emitChange();
                            handleCityChange();
                        "
                        name="city_id"
                        class="form-control"
                        :class="{ 'form-control-danger': form.errors.get('city_id') }"
                        v-model="form.city_id"
                    >
                        <option value="" disabled>Select A City</option>
                        <option v-for="city in cities" :value="city.id" v-text="city.name"></option>
                    </select>
                    <span
                        class="form-control-feedback"
                        v-if="form.errors.has('city_id')"
                        v-text="form.errors.get('city_id')"
                    ></span>
                </div>

                <div class="col-2 text-right">
                    <button class="btn btn-sm btn-secondary" @click.prevent="showCustomCity">Custom</button>
                </div>
            </div>
        </div>

        <div class="form-group row" v-if="customCityVisible" :class="{ 'has-danger': form.errors.get('custom_city') }">
            <div class="col-12">
                <label for="custom_city"
                    ><p>Custom City{{ isRequired('custom_city') }}</p></label
                >
                <input
                    @input="emitChange"
                    type="text"
                    name="custom_city"
                    class="form-control"
                    :class="{ 'form-control-danger': form.errors.get('custom_city') }"
                    v-model="form.custom_city"
                />
                <span
                    class="form-control-feedback"
                    v-if="form.errors.has('custom_city')"
                    v-text="form.errors.get('custom_city')"
                ></span>
            </div>
        </div>

        <div
            class="form-group row"
            v-if="!isHidden('postal_code')"
            :class="{ 'has-danger': form.errors.get('postal_code') }"
        >
            <div class="col-12">
                <label for="postal_code"
                    ><p>Postal Code{{ isRequired('postal_code') }}</p></label
                >
                <input
                    @input="emitChange"
                    type="text"
                    name="postal_code"
                    class="form-control"
                    :class="{ 'form-control-danger': form.errors.get('postal_code') }"
                    v-model="form.postal_code"
                />
                <span
                    class="form-control-feedback"
                    v-if="form.errors.has('postal_code')"
                    v-text="form.errors.get('postal_code')"
                ></span>
            </div>
        </div>

        <div
            class="form-group row mt-1"
            v-if="!isHidden('address')"
            :class="{ 'has-danger': form.errors.get('address') }"
        >
            <div class="col-lg-10 col-12">
                <label for="address"
                    ><p>Street Address{{ isRequired('address') }}</p></label
                >
                <input
                    @input="emitChange"
                    type="text"
                    name="address"
                    class="form-control"
                    :class="{ 'form-control-danger': form.errors.get('address') }"
                    v-model="form.address"
                />
                <span
                    class="form-control-feedback"
                    v-if="form.errors.has('address')"
                    v-text="form.errors.get('address')"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../form/form.js';

export default {
    name: 'address-fields',

    props: {
        usOnly: {
            type: Boolean,
            default: false,
        },
        visibleCountriesOnly: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            default() {
                return new Form({
                    custom_city: null,
                    country_id: 1,
                    state_id: null,
                    city_id: null,
                    postal_code: null,
                    address: null,
                });
            },
        },
        hiddenFields: {
            default() {
                return [];
            },
            type: Array,
        },
        requiredFields: {
            default() {
                return [];
            },
            type: Array,
        },
    },

    data() {
        return {
            customCityVisible: false,
            countries: false,
            states: false,
            cities: false,
        };
    },

    mounted() {
        if (this.usOnly) {
            this.countries = [
                {
                    name: 'United States',
                    id: 1,
                },
            ];
            this.fetchStates();
        } else {
            this.fetchCountries();
        }
    },

    methods: {
        showCustomCity() {
            this.form.city_id = null;
            this.form.postal_code = null;
            this.customCityVisible = true;
        },

        hideCustomCity() {
            this.form.custom_city = '';
            this.customCityVisible = false;
        },

        handleCountryChange() {
            this.form.state_id = null;
            this.form.city_id = null;
            this.form.postal_code = null;
            this.fetchStates();
            this.fetchCities();
        },

        handleStateChange() {
            this.form.city_id = null;
            this.form.postal_code = null;
            this.fetchCities();
        },

        handleCityChange() {
            this.form.postal_code = null;
            this.hideCustomCity();
        },

        fetchCountries() {
            axios
                .get(this.visibleCountriesOnly ? '/api/countries' : '/api/countries?all=true')
                .then(response => {
                    this.countries = response.data.data;
                    if (this.form.country_id) {
                        this.fetchStates();
                        this.fetchCities();
                    }
                })
                .catch(error => {
                    this.locationFetchFailed = error.data;
                });
        },
        fetchStates() {
            this.cities = {};
            this.states = {};
            axios
                .get('/api/states?country_id=' + this.form.country_id)
                .then(response => {
                    this.states = response.data.data;
                })
                .catch(error => {
                    this.locationFetchFailed = error.data;
                });
        },
        fetchCities() {
            this.cities = {};
            axios
                .get('/api/cities?state_id=' + this.form.state_id + '&country_id=' + this.form.country_id)
                .then(response => {
                    this.cities = response.data.data;
                })
                .catch(error => {
                    this.locationFetchFailed = error.data;
                });

            if (this.form.custom_city != '') {
                this.customCityVisible = true;
            }
        },

        isRequired(field) {
            return this.requiredFields.indexOf(field) >= 0 ? ' *' : '';
        },
        isHidden(field) {
            return this.hiddenFields.includes(field);
        },

        emitChange() {
            this.$emit('change');
        },
    },
};
</script>
