<template>
    <div @mouseout="hovered = 0" :class="{ pointer: !readOnly }" class="star-rating">
        <star
            @hover="handleHover"
            :hoveredRating="hovered"
            @rate="rate"
            :readOnly="readOnly"
            :amount="value - (n - 1)"
            v-for="n in 5"
            :value="n"
            :key="n"
        ></star>
        <p v-show="hovered && text" class="rating-text">{{ value }}</p>
        <i class="fa fa-check rate-success" v-show="success"></i>
    </div>
</template>

<script>
import star from './star.vue';

export default {
    props: {
        uuid: {
            type: String,
            required: false,
        },
        rating: {
            required: true,
        },
        text: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hovered: 0,
            success: false,
            value: 0,
        };
    },

    created() {
        this.value = this.rating;
    },

    components: { star },

    methods: {
        handleHover(rating) {
            if (!this.readOnly) {
                this.hovered = rating;
            }
        },

        handleGuest() {
            swal({
                title: 'Please Login',
                text: 'You must be logged in to rate this venue.',
                icon: 'warning',
                buttons: [true, 'Login'],
            }).then(willLogin => {
                if (willLogin) {
                    window.location = '/login?intended=' + window.location.pathname;
                }
            });
        },

        rate(amount) {
            if (this.readOnly) {
                return;
            }

            if (!window.User) {
                return this.handleGuest();
            }

            this.value = amount;

            axios
                .post('/api/venue-ratings', {
                    venue_uuid: this.uuid,
                    rating: amount,
                })
                .then(() => {
                    this.success = true;
                    setTimeout(() => {
                        this.success = false;
                    }, 3000);
                });
        },
    },
};
</script>

<style>
.star-rating {
    display: flex;
    color: gold;
}
.pointer {
    cursor: pointer;
}
.rating-text {
    color: darkgoldenrod;
    font-size: 0.8rem;
}
.rate-success {
    color: green;
    padding-left: 0.25rem;
}
</style>
