<template>
    <button @click="toggleMap" class="btn btn-sm btn-secondary m-1">
        <span v-if="showMap">
            <span class="d-block d-md-none">Map</span>
            <span class="d-none d-md-block">Hide Map</span>
        </span>
        <span v-else>
            <span class="d-block d-md-none">Map</span>
            <span class="d-none d-md-block">Show Map</span>
        </span>
    </button>
</template>

<script>
export default {
    name: 'map-toggle',

    data() {
        return {
            showMap: false,
        };
    },

    methods: {
        toggleMap() {
            this.showMap = !this.showMap;
        },
    },
};
</script>
