<template>
  <div class="container my-3">
    <div class="row">
      <div class="col-lg-8 col-xl-9 mb-2">
        <div class="card">
          <div class="card-header text-center" v-if="isCreate">
            Create New Show Availability
          </div>
          <div class="card-header text-center" v-else>
            Edit {{ form.title }}
          </div>
          <div class="card-body">
            <div @keydown="form.clearErrors($event.target.name)">
              <!-- Title -->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('title') }"
              >
                <label for="name">Title &#42;</label>
                <input
                  v-model="form.title"
                  type="text"
                  name="title"
                  class="form-control"
                  :class="{ 'form-control-danger': form.errors.get('title') }"
                />
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('title')"
                  v-text="form.errors.get('title')"
                >
                </span>
              </div>

              <!-- Description -->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('description') }"
              >
                <label for="name">Description &#42;</label>
                <textarea
                  v-model="form.description"
                  type="text"
                  name="description"
                  class="form-control"
                  :class="{
                    'form-control-danger': form.errors.get('description'),
                  }"
                >
                </textarea>
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('description')"
                  v-text="form.errors.get('description')"
                >
                </span>
              </div>

              <!-- Venues -->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('venue_name') }"
              >
                <!-- Existing Venues -->

                <label for="name">Where &#42;</label>
                <venue-select
                  v-if="!loading"
                  :error="form.errors.get('venue_name')"
                  v-model="form.venue_uuid"
                >
                </venue-select>
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('venue_name')"
                  v-text="form.errors.get('venue_name')"
                >
                </span>
                <p class="text-center py-1">
                  <small
                    >(OR enter a custom venue not in the IOTM database)</small
                  >
                </p>

                <!-- Custom Venue -->

                <input
                  type="input"
                  @input="handleCustomVenueInput"
                  v-model="form.venue_name"
                  placeholder="Enter a venue name"
                  class="form-control"
                />

                <transition name="fade">
                  <address-fields
                    :form="form"
                    @change="form.clearErrors()"
                    class="mt-2"
                    :requiredFields="[
                      'postal_code',
                      'address',
                      'country',
                      'state',
                      'city',
                    ]"
                    v-if="form.venue_name"
                  >
                  </address-fields>
                </transition>
              </div>

              <!-- Genres -->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('genres') }"
              >
                <label for="genres">Genres *</label>
                <genre-select
                  v-model="form.genres"
                  size="small"
                  @update:modelValue="
                    form.clearErrors();
                    console.log('@update:modelValue');
                  "
                  name="genres"
                ></genre-select>
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('genres')"
                  v-text="form.errors.get('genres')"
                >
                </span>
              </div>

              <!-- Blacklist Genres -->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('blacklist_genres') }"
              >
                <label for="blacklist_genres">Excluded Genres</label>
                <genre-select
                  v-model="form.blacklist_genres"
                  size="small"
                  @update:modelValue="form.clearErrors()"
                  name="blacklist_genres"
                ></genre-select>
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('blacklist_genres')"
                  v-text="form.errors.get('blacklist_genres')"
                >
                </span>
              </div>

              <!-- Datepickers for selected dates-->

              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('date') }"
              >
                <label for="date">When &#42;</label>
                <div class="row align-items-center" v-for="date in form.dates">
                  <div class="col-md-6 d-flex flex-row">
                    <div
                      class="btn-sm btn btn-danger mr-1 flex-grow-0 d-flex align-items-center justify-content-center"
                      @click="removeDate(date)"
                    >
                      <i class="fa fa-minus" />
                    </div>
                    <div
                      class="el-form-item mb-0 flex-grow-1"
                      :class="{ 'is-error': form.errors.has('date') }"
                    >
                      <flat-pickr
                        class="w-100 form-control"
                        :config="config"
                        @input="form.clearErrors()"
                        v-on:change="form.clearErrors('change')"
                        placeholder="Select Date"
                        v-model="date.date"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <select
                      @change="form.clearErrors()"
                      v-model="date.start"
                      class="form-control my-2"
                    >
                      <option v-for="s in startOptions">
                        {{ s }}
                      </option>
                      <option :value="date.start">
                        {{ date.start }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Datepickers for new dates-->

                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div
                      class="el-form-item mb-0"
                      :class="{ 'is-error': form.errors.has('date') }"
                    >
                      <flat-pickr
                        class="w-100 form-control"
                        :config="config"
                        @input="addDate()"
                        v-on:change="addDate()"
                        placeholder="Add Date"
                        v-model="newDate.date"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <select
                      @change="addDate()"
                      v-model="newDate.start"
                      class="form-control my-2"
                    >
                      <option value="" default>Select a Start Time</option>
                      <option v-for="s in startOptions">
                        {{ s }}
                      </option>
                    </select>
                  </div>
                </div>

                <span
                  class="form-control-feedback text-danger"
                  v-show="form.errors.has('dates')"
                  v-text="form.errors.get('dates')"
                >
                </span>
              </div>

              <!-- Demographic -->

              <label for="demographic">Target Demographic *</label>
              <div
                class="form-group"
                :class="{ 'has-danger': form.errors.get('demographic') }"
              >
                <select
                  class="form-control"
                  @change="form.clearErrors()"
                  name="demographic"
                  v-model="form.demographic.type"
                >
                  <option v-for="option in demographicOptions">
                    {{ option }}
                  </option>
                </select>
                <span
                  class="form-control-feedback"
                  v-show="form.errors.has('demographic')"
                  v-text="form.errors.get('demographic')"
                >
                </span>
              </div>

              <!-- Multiple State Select -->

              <div class="form-group">
                <state-select
                  v-if="form.demographic.type === 'Multiple States'"
                  :country="form.country_id || ''"
                  v-model="form.demographic.states"
                >
                </state-select>
              </div>

              <!-- Photos -->

              <label for="name">Photos</label>
              <div class="card-body px-4">
                <photo-upload-container
                  v-if="uuid"
                  :uuid="uuid"
                  type="venueAvail"
                  :url="`/api/venue-availabilities/${uuid}/photos`"
                />
              </div>
              <div class="d-flex flex-row justify-content-center form-group">
                <btn-state
                  v-on:click="onSubmit"
                  :state="form.state"
                  class="btn btn-secondary"
                >
                  Next
                  <a class="p-2">
                    <i class="fa fa-arrow-right"></i>
                  </a>
                </btn-state>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-xl-3">
        <news></news>
      </div>
    </div>
  </div>
</template>

<script>
import addressFields from "../../components/addressFields.vue";
import btnState from "../../components/btnState.vue";
import genreSelect from "../../components/genreSelect.vue";
import Form from "../../form/form.js";
import news from "../../components/news.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import PhotoUploadContainer from "../../components/PhotoUploadContainer.vue";

export default {
  props: {
    editingUuid: {
      required: false,
      type: String,
    },
  },

  components: {
    PhotoUploadContainer,
    addressFields,
    btnState,
    news,
    genreSelect,
    flatPickr,
  },

  data() {
    return {
      loading: true,
      uuid: "",
      isCreate: false,
      start: moment().startOf("day").add(20, "hours").format("h:mm a"),
      demographicOptions: [
        "National",
        "Statewide",
        "Multiple States",
        "25 Mile Radius",
        "50 Mile Radius",
        "100 Mile Radius",
      ],
      form: new Form({
        title: "",
        description: "",
        venue_uuid: null,
        venue_name: null,
        genres: [],
        blacklist_genres: [],
        dates: [],
        demographic: {
          type: "National",
          states: [],
        },
        city_id: "",
        custom_city: "",
        state_id: "",
        country_id: 1,
        address: "",
        postal_code: "",
      }),
      newDate: {
        date: null,
        start: "",
      },
      existingDates: [],
      config: {
        dateFormat: "Y-m-d",
      },
      genres: [],
    };
  },

  created() {
    if (!this.editingUuid) {
      this.isCreate = true;
      axios
        .post("/api/venue-availabilities/", {
          title: "title",
          description: "description",
          venue_uuid: null,
        })
        .then((response) => {
          this.uuid = response.data.data.uuid;
        });
      this.loading = false;
    } else {
      this.uuid = this.editingUuid;
      axios.get("/api/venue-availabilities/" + this.uuid).then((response) => {
        this.form = new Form(Object.assign(this.form, response.data.data));

        this.loading = false;

        //// default start time
        //if (this.form.date) {
        //this.start = moment(this.form.date).format('h:mm a');
        //} else {
        //// default to tomorrow 8pm
        //this.form.date = moment().add(1, 'days').toDate();
        //this.start = moment().startOf('day').add(20, 'hours').format('h:mm a');
        //}

        this.form.dates = this.parseDates(response.data.data.dates);
        this.existingDates = this.parseDates(response.data.data.dates);
        this.form.genres = response.data.data.genres.data.map((x) => x.id);
        this.form.blacklist_genres =
          response.data.data.blacklist_genres.data.map((x) => x.id);

        if (this.form.demographic) {
          this.form.demographic = JSON.parse(this.form.demographic);
        } else {
          this.form.demographic = {
            type: "National",
            states: [],
          };
        }
      });
    }
  },

  computed: {
    startOptions() {
      let starts = [];
      const start = moment().startOf("day").add(8, "hours");
      const end = moment().endOf("day");
      const interval = 15;
      while (start.add(interval, "minutes") < end) {
        starts.push(start.format("h:mm a"));
      }
      return starts;
    },
  },

  methods: {
    // show when custom city has input. hide when cleared
    handleCustomVenueInput(e) {
      this.$nextTick(() => {
        if (e.target.value) {
          this.form.venue_uuid = null;
        }
      });
    },

    handleVenueSelect(e) {
      this.form.venue_uuid = e.uuid;
      this.form.venue_name = "";
    },

    submitForm(uuid) {
      console.log(this.form);
      var $v = this;
      $v.form
        .put("/api/venue-availabilities/" + uuid)
        .then(function (response) {
          $v.response = response.data;
          window.location =
            "/show-availabilities/" +
            response.data.data.slug +
            "/" +
            response.data.data.uuid;
        })
        .catch(function (error) {
          $v.response = error.data;
        });
    },

    onSubmit() {
      let x = moment(this.form.date).format("YYYY-MM-DD");
      let time = moment(x + " " + this.start, "YYYY-MM-DD h:mm a");
      let date = time.format("YYYY-MM-DD HH:mm:ss");
      this.form.date = date;
      this.submitForm(this.uuid);
    },

    addDate() {
      this.form.clearErrors();

      if (!this.newDate.date || !this.newDate.start) {
        return;
      }

      this.form.dates.push(this.newDate);

      this.newDate = {
        date: null,
        start: "",
      };
    },

    removeDate(cancelledDate) {
      this.form.dates = this.form.dates.filter((date) => {
        return date != cancelledDate;
      });
    },

    parseDates(dates) {
      const ret = dates.data.map((date) => {
        return {
          date: moment(date.date).format("YYYY-MM-DD"),
          start: moment(date.date).format("h:mm a"),
        };
      });

      return ret;
    },

    logEvent(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
#thumbNail {
  max-width: 260px;
  max-height: 260px;
}
.fileInput {
  width: 50rem;
}
</style>
