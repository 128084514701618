<template>
  <div class="card">
    <div class="card-header">
      <h4 class="m-0">Edit Photo</h4>
    </div>

    <form
      class="card-body"
      @submit.prevent="submit"
      @keydown="form.clearErrors($event.target.name)"
    >
      <div class="form-group">
        <img
          v-if="form.url"
          class="img-fluid"
          :src="form.url + '?w=300&h=300&fit=crop'"
        />
      </div>

      <div
        class="form-group"
        :class="{ 'has-danger': form.errors.get('venue_uuid') }"
      >
        <label for="venue_uuid">Venue</label>
        <venue-select
          @update:modelValue="form.clearErrors()"
          v-model="form.venue_uuid"
        ></venue-select>
        <span
          class="form-control-feedback"
          v-show="form.errors.has('venue_uuid')"
          v-text="form.errors.get('venue_uuid')"
        ></span>
      </div>

      <div
        class="form-group"
        :class="{ 'has-danger': form.errors.get('title') }"
      >
        <label for="title">Title</label>
        <input
          :class="{ 'form-control-danger': form.errors.get('title') }"
          name="title"
          v-model="form.title"
          class="form-control"
        />
        <span
          class="form-control-feedback"
          v-show="form.errors.has('title')"
          v-text="form.errors.get('title')"
        ></span>
      </div>

      <div
        class="form-group"
        :class="{ 'has-danger': form.errors.get('caption') }"
      >
        <label for="caption">Caption</label>
        <textarea
          :class="{ 'form-control-danger': form.errors.get('caption') }"
          name="caption"
          v-model="form.caption"
          class="form-control"
        ></textarea>
        <span
          class="form-control-feedback"
          v-show="form.errors.has('caption')"
          v-text="form.errors.get('caption')"
        ></span>
      </div>

      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              :disabled="isProfile"
              class="form-check-input"
              type="checkbox"
              v-model="profile"
            />
            Set as profile picture
          </label>
        </div>
      </div>

      <div class="form-group d-flex align-items-center mt-3">
        <btn-state class="btn btn-primary" :state="form.state">
          Update
        </btn-state>
        <a href="/media-photos" class="pl-2 btn-link">
          <span v-if="form.state === 'success'"> done </span>
          <span v-else> cancel </span>
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "../../form/form.js";
import btnState from "../../components/btnState.vue";

export default {
  components: { btnState },

  props: ["uuid"],

  data() {
    return {
      form: new Form(),
      profile: false,
    };
  },

  created() {
    this.fetchPhoto();
  },

  computed: {
    isProfile() {
      return this.form && this.form.url === window.User.img_url;
    },
  },

  methods: {
    setProfile() {
      axios
        .post("/api/users/" + window.User.uuid + "/thumbnail/" + this.form.uuid)
        .then(() => {
          window.location.href = "/photos/" + this.form.uuid + "/edit";
        });
    },

    fetchPhoto() {
      axios.get("/api/photos/" + this.uuid).then((res) => {
        this.form = new Form(res.data.data);
        this.profile = this.isProfile;
      });
    },

    submit() {
      this.form.put("/api/photos/" + this.uuid).then(() => {
        if (this.profile && !this.isProfile) {
          this.setProfile();
        }
      });
    },
  },
};
</script>
